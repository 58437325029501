/* eslint-disable react/style-prop-object */
import React from "react";
import { ButtonIcon, Poptip, Table, Typography } from "@optimizely/axiom";
import { InstanceRole } from "../../../../domain/InstanceRole.interface";
import styles from "../AccessManagementUserForm/AccessManagementUserForm.module.scss";
import classNames from "classnames";
import { ALL_PROJECTS_NAME } from "../../../../constants/constants";
import { AccessDetailsRow } from "../../../../domain/AccessDetails";

const renderRoles = (roles: InstanceRole[]) => {
    if (roles?.length === 0) return "N/A";

    return roles.map((role, index) => {
        const roleName = role.isInstanceRole ? role.displayName : role.displayName.split(" - ")[0];
        return <div key={role.id}>{index === roles.length - 1 ? roleName : `${roleName},`}</div>;
    });
};

export type AccessDetailsTableRowProps = {
    access: AccessDetailsRow;
    showProjectColumn: boolean;
    onRemove?: ({ accessDetailsRow }: { accessDetailsRow: AccessDetailsRow }) => void;
};

export const AccessDetailsTableRow = ({ access, showProjectColumn, onRemove }: AccessDetailsTableRowProps) => {
    const { instanceName, nickname, projectName, roles, isCustomGroup, groupName } = access;

    return (
        <Table.TR>
            <Table.TD>{nickname || instanceName}</Table.TD>
            {showProjectColumn && <Table.TD>{projectName || ALL_PROJECTS_NAME}</Table.TD>}
            <Table.TD>
                {renderRoles(roles)}
                {isCustomGroup && (
                    <Typography type="xxs" tag="div" className="push--top">
                        This role is assigned through a group. Group Name: <strong>{groupName}</strong>
                    </Typography>
                )}
            </Table.TD>
            {onRemove && (
                <Table.TD>
                    <Poptip trigger={"mouseenter"} content="Remove Access" isInline>
                        <ButtonIcon
                            className={classNames(styles["icon-button"])}
                            iconName="trash-can"
                            size="small"
                            style="plain"
                            data-testid="remove-access"
                            onClick={() => onRemove({ accessDetailsRow: access })}
                        />
                    </Poptip>
                </Table.TD>
            )}
        </Table.TR>
    );
};

AccessDetailsTableRow.displayName = "AccessDetailsTableRow";
