export interface IOrganizationProductInstance {
    id: string;
    name: string;
    navigationUrl?: string;
    nickname?: string;
    optiIdEnabled?: boolean;
    region?: string;
    technicalContactEmail?: string;
    technicalContactName?: string;
}

export class OrganizationProduct {
    constructor({
        allowCustomerDefinedRoles = false,
        hasUsageDashboard,
        isService,
        id,
        name,
        instances
    }: {
        allowCustomerDefinedRoles?: boolean;
        hasUsageDashboard?: boolean;
        isService?: boolean;
        id: string;
        name: string;
        instances: IOrganizationProductInstance[];
    }) {
        this.allowCustomerDefinedRoles = allowCustomerDefinedRoles;
        this.hasUsageDashboard = hasUsageDashboard || false;
        this.isService = isService || false;
        this.id = id;
        this.name = name;
        this.instances = instances;
    }
    allowCustomerDefinedRoles: boolean;
    hasUsageDashboard: boolean;
    isService: boolean;
    id: string;
    name: string;
    instances: IOrganizationProductInstance[];
}
