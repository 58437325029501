import React from "react";
import { Table } from "@optimizely/axiom";
import { LoadingTable } from "../LoadingIndicator/LoadingTable";
import { SupportOrganizationPermissions } from "../../pages/Support/Access/SupportAccess";
import { DetailedSupportTableRow } from "./DetailedSupportTableRow";

type SupportTableProps = {
    orgPermissions?: SupportOrganizationPermissions[];
    loading?: boolean;
    onRemove: ({ orgPermission }: { orgPermission: SupportOrganizationPermissions }) => void;
};
export const DetailedSupportTable = (props: SupportTableProps) => {
    const { orgPermissions = [], loading, onRemove } = props;

    if (loading) {
        return <LoadingTable headers={["Organization", "Access Level", "Revoke Date"]} />;
    }

    return (
        <Table
            className="push-double--bottom"
            density="loose"
            // eslint-disable-next-line react/style-prop-object
            style="rule-no-bottom-border"
        >
            <Table.THead>
                <Table.TR>
                    <Table.TH width="30%">Organization</Table.TH>
                    <Table.TH width="45%">Access Level</Table.TH>
                    <Table.TH width="20%">Revoke Date</Table.TH>
                    <Table.TH width="5%"></Table.TH>
                </Table.TR>
            </Table.THead>
            <Table.TBody>
                {orgPermissions?.map((orgPerm: SupportOrganizationPermissions, i: number) => (
                    <DetailedSupportTableRow key={i} orgPermission={orgPerm} onRemove={onRemove} />
                ))}
            </Table.TBody>
        </Table>
    );
};

DetailedSupportTable.dislayName = "DetailedSupportTable";
