import { IdentityProvider as RestIdentityProvider } from "../rest-model/IdentityProvider";
import {
    OrganizationProductInstances as RestOrganizationProductInstances,
    IOrganizationProduct as RestOrganizationProduct
} from "../rest-model/OrganizationProductInstances";
import { OrganizationProduct } from "../domain/OrganizationProduct";
import { IdentityProvider } from "../domain/IdentityProvider";
import { ProductInstance } from "../domain/ProductInstance";
import { ADMINCENTER_PRODUCT_ID } from "../constants/constants";

export class OrganizationAdapter {
    public adaptIdpToDomain(restIdp?: RestIdentityProvider, issuerUrl?: string, ssoUrl?: string): IdentityProvider {
        return new IdentityProvider({
            domains: restIdp?.domains || [],
            id: restIdp?.id || "",
            idpId: restIdp?.idpId,
            idpName: restIdp?.idpName || "Connection",
            type: ssoUrl ? "SAML" : "OIDC",
            audienceUrl: restIdp?.audienceUri || "",
            acsUrl: restIdp?.acsUrl || "",
            issuerUrl: issuerUrl || restIdp?.issuerUrl || "",
            ssoUrl: ssoUrl || restIdp?.ssoUrl || "",
            authorizationUrl: restIdp?.authorizationUrl || "",
            clientId: restIdp?.clientId || "",
            clientSecret: restIdp?.clientSecret || "",
            jwksUrl: restIdp?.jwksUrl || "",
            provider: restIdp?.provider || "",
            tokenUrl: restIdp?.tokenUrl || "",
            userInfoUrl: restIdp?.userInfoUrl || "",
            wellKnownUrl: restIdp?.wellKnownUrl || "",
            ...(restIdp || {})
        });
    }

    public adaptProductInstancesToDomain(restOrgProdInstances: RestOrganizationProductInstances): ProductInstance[] {
        const prodInstances: ProductInstance[] = [];
        restOrgProdInstances.products.forEach((product) => {
            const productName = product.name;
            const productId = product.id;

            product.instances.forEach((instance) => {
                const { id, name, navigationUrl, optiIdEnabled, OptiIdEnabled, ...rest } = instance;
                const prodInstance = new ProductInstance({
                    instanceId: id,
                    instanceName: name,
                    navigationUrl,
                    optiIdEnabled:
                        productId === ADMINCENTER_PRODUCT_ID
                            ? true
                            : /true/i.test(optiIdEnabled || OptiIdEnabled || ""),
                    productId,
                    productName,
                    roles: [],
                    ...rest
                });

                prodInstances.push(prodInstance);
            });
        });

        return prodInstances;
    }

    public adaptOrganizationProductsToDomain(restOrgProducts: RestOrganizationProduct[]): OrganizationProduct[] {
        const adaptedOrgProducts = restOrgProducts.map((product) => {
            const { instances, ...restOfProduct } = product;

            const adaptedDomainInstances = instances.map((instance) => {
                const { optiIdEnabled, OptiIdEnabled, ...restofInstance } = instance;
                return {
                    optiIdEnabled:
                        product.id === ADMINCENTER_PRODUCT_ID
                            ? true
                            : /true/i.test(optiIdEnabled || OptiIdEnabled || ""),
                    ...restofInstance
                };
            });

            return new OrganizationProduct({
                instances: adaptedDomainInstances,
                ...restOfProduct
            });
        });

        return adaptedOrgProducts;
    }
}
