/* eslint-disable react/style-prop-object */
import React, { useState } from "react";
import { Typography, Button, Attention } from "@optimizely/axiom";
import { isEmail } from "../../../../lib/validators";
import { useForm, useFormState } from "react-hook-form";
import { adaptApiErrors, IApiError } from "../../../../services/ErrorMessageAdapter";
import {
    UNLOCK_USER_SUCCESS_MESSAGE,
    ACTIVATE_USER_SUCCESS_MESSAGE,
    ACCOUNT_ACTIVATION_UNKNOWN_ERROR_MESSAGE
} from "../../../../constants/constants";
import { emitToast } from "../../../../lib/toaster-utils";
import { useUser } from "../../../../hooks/useUser/useUser";

interface IAccountActivationFormValues {
    email: string;
    apiError: { message: string };
}

export const AccountActivation = () => {
    const {
        register,
        watch,
        getValues,
        setError,
        clearErrors,
        control,
        formState: { errors }
    } = useForm<IAccountActivationFormValues>({
        mode: "onChange"
    });

    const { isSubmitting } = useFormState({
        control
    });
    const { email } = getValues();
    watch("email");

    const { unlockUser, activateUser } = useUser({ email });
    const [loadingUnlock, setLoadingUnlock] = useState(false);
    const [loadingActivate, setLoadingActivate] = useState(false);
    const disableButton = !email || !!errors.email;

    const handleError = (apiErrors: IApiError[] | Error) => {
        if (Array.isArray(apiErrors)) {
            const errors = adaptApiErrors(apiErrors);
            errors.forEach((error) => {
                setError(error.name as any, {
                    type: "individualFieldApiError",
                    message: error.message
                });
            });
        } else {
            setError("apiError", {
                type: "apiError",
                message: ACCOUNT_ACTIVATION_UNKNOWN_ERROR_MESSAGE
            });
        }
    };

    const handleUnlockUser = async () => {
        clearErrors("apiError");
        setLoadingUnlock(true);
        unlockUser({ email })
            .then(async () => {
                emitToast({ message: UNLOCK_USER_SUCCESS_MESSAGE });
            })
            .catch(handleError)
            .finally(() => {
                setLoadingUnlock(false);
            });
    };

    const handleActivateUser = async () => {
        clearErrors("apiError");
        setLoadingActivate(true);
        activateUser({ email })
            .then(async () => {
                emitToast({ message: ACTIVATE_USER_SUCCESS_MESSAGE });
            })
            .catch(handleError)
            .finally(() => {
                setLoadingActivate(false);
            });
    };

    if (errors.apiError && isSubmitting) {
        clearErrors("apiError");
    }

    return (
        <form className="flex flex--column user-form">
            <div className="push-double--top push-double--bottom">
                <Typography type="header1" className="push-triple--top">
                    Account Activation
                </Typography>
                <Typography type="body" tag="p">
                    Use this form to unlock a user or resend their invitation.
                </Typography>
            </div>
            <div className="oui-dropdown-group push-double--ends width--4-12">
                <input
                    placeholder="User email..."
                    aria-describedby="user-email-error"
                    className="oui-text-input"
                    id="user-email"
                    type="email"
                    {...register("email", {
                        validate: { isEmail },
                        required: { value: true, message: "Email is required" }
                    })}
                />
                {errors.email && (
                    <div className="oui-form-bad-news">
                        <span className="oui-form-note">{errors.email.message}</span>
                    </div>
                )}
            </div>
            <div className="oui-button-row flex soft-double--ends oui-button-row--right">
                <Button
                    key="unlock-user-button"
                    isLoading={loadingUnlock}
                    loadingText="Please wait..."
                    style="highlight"
                    className="oui-button oui-button--highlight oui-button--default"
                    isDisabled={disableButton || loadingActivate}
                    onClick={handleUnlockUser}
                >
                    Unlock User
                </Button>
                <Button
                    key="resend-invite-button"
                    isLoading={loadingActivate}
                    loadingText="Please wait..."
                    style="highlight"
                    className="oui-button oui-button--highlight oui-button--default"
                    isDisabled={disableButton || loadingUnlock}
                    onClick={handleActivateUser}
                >
                    Resend Invitation
                </Button>
            </div>
            {errors.apiError && (
                <Attention alignment="left" className="push--top push-quad--bottom width--4-12" type="bad-news">
                    {errors.apiError.message}
                </Attention>
            )}
        </form>
    );
};

AccountActivation.displayName = "AccountActivationPage";
