import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

import LayoutWrapper from "../../pages/Layout/LayoutWrapper";
import { LoadingIndicator } from "../LoadingIndicator/LoadingIndicator";
import { Unauthorized } from "../../pages/Unauthorized/Unauthorized";
import { useUserContext } from "../../../providers/UserProvider";
import { useSession } from "../../../hooks/useSession/useSession";
import { getCurrentRoute } from "../../../lib/navigation-helpers";

// Adopted from https://raw.githubusercontent.com/okta/okta-react/master/samples/routing/react-router-dom-v6/src/components/SecureRoute.tsx
// due to incomptability between current react-router v6 and recommended by the Okta devs at
// https://github.com/okta/okta-react/issues/178#issuecomment-1150407182.
export const RequireAuth = () => {
    const location = useLocation();
    const { isAuthenticated, signIn, isLoading: isLoadingSession } = useSession();
    const {
        hasAdminAccess,
        profile,
        organizationId,
        organizationName,
        isLoading: isLoadingUserDetails,
        getAvailableRoutes
    } = useUserContext();

    useEffect(() => {
        if (!isAuthenticated && !isLoadingSession) {
            signIn();
        }
    }, [isAuthenticated, isLoadingSession, signIn]);

    useEffect(() => {
        if (process.env.REACT_APP_GAINSIGHT_ENV_KEY !== "local" && profile && !isLoadingUserDetails) {
            window.aptrinsic(
                "identify",
                //User Fields
                {
                    id: profile.id, // Required for logged in app users
                    email: profile.email,
                    firstName: profile.firstName,
                    lastName: profile.lastName
                },
                //Account Fields
                {
                    id: organizationId, //Required
                    name: organizationName
                }
            );
        }
    }, [profile, organizationId, organizationName, isLoadingUserDetails]);

    if (!isAuthenticated || isLoadingUserDetails || isLoadingSession) {
        return <LoadingIndicator logo />;
    }

    if (!hasAdminAccess) {
        const urlWithParams = new URL(process.env.REACT_APP_HOME_APP_URL || "");
        urlWithParams.searchParams.append("login_hint", `${profile?.email}`);
        urlWithParams.searchParams.append("orgId", `${organizationId}`);

        window.location.href = urlWithParams.toString();

        return <LoadingIndicator logo />;
    }

    const availableRoutes = getAvailableRoutes();
    const currentRoute = getCurrentRoute({ routes: availableRoutes || [], pathname: location.pathname });

    if (!currentRoute && location.pathname !== "/") {
        return (
            <LayoutWrapper>
                <Unauthorized />
            </LayoutWrapper>
        );
    }

    return (
        <LayoutWrapper>
            <Outlet />
        </LayoutWrapper>
    );
};
