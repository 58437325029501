export interface IOrganizationSettings {
    genAI: { enabled: boolean };
}

export class OrganizationSettings implements IOrganizationSettings {
    genAI: { enabled: boolean };

    constructor({ Settings }: { Settings: IOrganizationSettings }) {
        this.genAI = Settings.genAI;
    }
}
