import useSWR from "swr";

import { useServicesContext } from "../../providers/ServicesProvider";
import { User } from "../../domain/User";
import { SWR_KEY_PREFIX } from "../../constants/constants";

export const useUser = ({
    email,
    includeExternalStatus = false
}: {
    email: string | undefined;
    includeExternalStatus?: boolean;
}) => {
    const { userService } = useServicesContext();

    const {
        data: user,
        error,
        mutate
    } = useSWR<User | undefined, Error>(
        email ? `${SWR_KEY_PREFIX.USERS}/api/users/${email}&?includeExternalStatus=${includeExternalStatus}` : null,
        () => userService.getUserByEmail(email!, includeExternalStatus)
    );

    const unlockUser = async ({ email }: { email: string }) => {
        const user = await userService.unlockUser(email);
        await mutate();
        return user;
    };

    const activateUser = async ({ email }: { email: string }) => {
        const user = await userService.activateUser(email);
        await mutate();
        return user;
    };

    return {
        user,
        isLoading: !error && !user,
        error,
        revalidate: mutate,
        activateUser,
        unlockUser
    };
};
