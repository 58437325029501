export class ProductAttribute {
    constructor(attribute: any) {
        this.description = attribute?.Description;
        this.id = attribute?.Id;
        this.instanceId = attribute?.InstanceId;
        this.isActive = attribute?.IsActive;
        this.key = attribute?.Key;
        this.name = attribute?.Name;
        this.type = attribute.Type;
        this.values = attribute?.Values || attribute?.SelectedValues || [];
        this.scopes = attribute?.Scopes || [];
        this.productId = attribute?.ProductId;
    }

    description: string;
    id: string;
    instanceId: string;
    isActive: boolean;
    key: string;
    name: string;
    type: string;
    values: string[];
    scopes: any[];
    productId: string;
}
