import { useCallback, useEffect } from "react";
import useSWR from "swr";

import { Products } from "../../domain/Products";
import { useServicesContext } from "../../providers/ServicesProvider";
import { IOrganizationProductInstance } from "../../domain/OrganizationProduct";
import { datadogRum } from "@datadog/browser-rum";
import { useUpdateSync } from "../useUpdateSync/useUpdateSync";
import { EVENT_TYPES, SWR_KEY_PREFIX } from "../../constants/constants";

export const useProducts = ({
    organizationId,
    includeServices = false
}: {
    organizationId?: string;
    includeServices?: boolean;
}) => {
    const { userService, instanceService } = useServicesContext();
    const { fireChangeEvent } = useUpdateSync();

    const { data, error, mutate } = useSWR<Products, Error>(
        organizationId
            ? `${SWR_KEY_PREFIX.INSTANCES}/api/products/${organizationId}?includeServices=${includeServices}`
            : null,
        () => {
            return userService.getOrganizationProducts({
                organizationId: organizationId as string,
                includeServices
            });
        }
    );

    useEffect(() => {
        if (error) {
            datadogRum.addError(error);
        }
    }, [error]);

    const updateInstance = async ({ instance }: { instance: IOrganizationProductInstance }) => {
        const result = await instanceService?.updateInstance({ instance });

        fireChangeEvent({
            type: EVENT_TYPES.UPDATE_INSTANCE,
            data: {
                instance: {
                    instanceId: instance.id,
                    navigationUrl: instance.navigationUrl,
                    nickname: instance.nickname,
                    technicalContactEmail: instance.technicalContactEmail,
                    technicalContactName: instance.technicalContactName,
                    optiIdEnabled: instance.optiIdEnabled
                }
            }
        });

        return result;
    };

    // GET request, not bound to SWR hook
    const getInstanceDetails = useCallback(
        async ({ userGroupId, instanceId }: { userGroupId: string; instanceId: string }) => {
            return await userService.getProductInstance({ userGroupId, instanceId });
        },
        [userService]
    );

    // GET request, not bound to SWR hook
    const getUserGroupInstances = useCallback(
        async ({ userGroupId }: { userGroupId: string }) => {
            return await userService.getProductInstances({
                userGroupId
            });
        },
        [userService]
    );

    // GET request, not bound to SWR hook
    // TODO: If we upgrade SWR leverage the SWR Trigger method for this to save on caching
    const getProductsForOrganization = async ({ organizationId }: { organizationId: string }) => {
        const products = await userService.getOrganizationProducts({
            organizationId: organizationId as string,
            includeServices
        });
        return { products: products?.organizationProducts };
    };

    return {
        getInstanceDetails,
        getUserGroupInstances,
        instances: data?.productInstances,
        products: data?.organizationProducts,
        contactDetails: data?.contactDetails,
        isLoading: !error && !data,
        getProductsForOrganization,
        updateInstance,
        error,
        revalidate: mutate
    };
};
