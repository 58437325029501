import React from "react";
import { NavLink, useParams } from "react-router-dom";
import "./NavLinks.css";
import { ITab } from "../../../lib/navigation-types";

export const NavLinks = ({ links }: { links: ITab[] }) => {
    const { productId, instanceId, projectId } = useParams();
    return (
        <div className="nav-links">
            {(links || []).map((link, linkIndex) => {
                return (
                    <NavLink
                        key={linkIndex}
                        className={({ isActive }) => (isActive ? "active" : "")}
                        to={link.route
                            .replace("<productId>", productId || "")
                            .replace("<instanceId>", instanceId || "")
                            .replace("<projectId>", projectId || "")}
                        end
                    >
                        {link.title}
                    </NavLink>
                );
            })}
        </div>
    );
};

NavLinks.diplayName = "NavLinks";
