import React from "react";
import { useLocation, useParams } from "react-router-dom";
import classNames from "classnames";
import { NavigationBar } from "../../components/NavigationBar/NavigationBar";
import styles from "./LayoutWrapper.module.scss";
import Layout from "./Layout";
import { OptiCommonHeader } from "../../components/OptiCommonHeader/OptiCommonHeader";
import { getCurrentRoute, getSecondaryNavigation } from "../../../lib/navigation-helpers";
import { useUserContext } from "../../../providers/UserProvider";
import { optimizelyInstance } from "../../../feature-flags/optimizelyInstance";
import { useProducts } from "../../../hooks/useProducts/useProducts";
import { useOrganization } from "../../../hooks/useOrganization/useOrganization";
import { UnauthorizedWithGlobalBar } from "../Unauthorized/UnauthorizedWithGlobalBar";
import { ITab } from "../../../lib/navigation-types";

const LayoutWrapper = ({ children }: { children?: React.ReactNode }) => {
    const location = useLocation();
    const { getAvailableRoutes, canUserDoAction, organizationId } = useUserContext();
    const { products } = useProducts({ organizationId, includeServices: true });
    const { organization } = useOrganization({ id: organizationId });
    const { pathname } = location;
    const { productId, instanceId, projectId } = useParams();

    const routes = getAvailableRoutes();
    const currentRoute = routes
        ? getCurrentRoute({ routes, pathname: location.pathname, productId, instanceId, projectId })
        : undefined;
    let availableTabs: ITab[] | undefined;

    const hasUserPermissions = (tab: ITab): boolean => {
        if (!tab?.actions) return true;

        if (tab?.context) {
            return tab.context.some((c) => {
                const contextToSend = {
                    ...c,
                    instanceId: c.instanceId?.replace("<instanceId>", instanceId || ""),
                    productId: c.productId?.replace("<productId>", productId || "")
                };
                return canUserDoAction({ action: tab.actions!, context: contextToSend || undefined });
            });
        }

        return canUserDoAction({ action: tab.actions });
    };

    if (routes && currentRoute) {
        availableTabs = currentRoute.tabs?.filter((tab) => {
            const userHasPermissions = hasUserPermissions(tab);

            const { enabled: featureFlagAccess } = tab.featureFlag
                ? optimizelyInstance.decide(tab.featureFlag.flag)
                : { enabled: true };

            const showForFeatureFlag = tab.featureFlag?.offToShow ? !featureFlagAccess : featureFlagAccess;

            const showForProduct = tab.showForProduct ? tab.showForProduct === productId : true;
            const showForSsoSettings = tab.requiresDisabledSso ? !organization?.ssoEnabled : true;

            return (
                userHasPermissions &&
                showForProduct &&
                (tab.requiresDisabledSso ? showForFeatureFlag || showForSsoSettings : showForFeatureFlag)
            );
        });
    }

    const Submenu = getSecondaryNavigation({ path: pathname });
    const productName = products?.find((p) => p.id === productId)?.name || "";
    const instance = products?.flatMap((p) => p.instances).find((i) => i.id === instanceId);
    const instanceName = instance?.nickname || instance?.name || "";
    const pageTitle = (currentRoute?.title || "")
        .replace("<productName>", productName)
        .replace("<instanceName>", instanceName);

    if (!currentRoute && location.pathname !== "/") {
        return <UnauthorizedWithGlobalBar />;
    }

    return (
        <>
            <OptiCommonHeader />
            <div className={classNames("flex", styles["layout-wrapper"])}>
                <div className={styles["layout-wrapper__navigation"]}>
                    <NavigationBar />
                </div>
                {!!Submenu && (
                    <div className={styles["layout-wrapper__navigation"]}>
                        <Submenu />
                    </div>
                )}
                <div className="flex--1">
                    <Layout
                        headerText={pageTitle}
                        tabs={availableTabs || []}
                        pageDescription={currentRoute?.pageDescription}
                    >
                        {children}
                    </Layout>
                </div>
            </div>
        </>
    );
};

export default LayoutWrapper;
