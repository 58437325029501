/* eslint-disable react/style-prop-object */
import { Attention, Button, Typography } from "@optimizely/axiom";

import { ATTRIBUTE_ROLES, INVITATION_STATUS } from "../../../constants/constants";

import styles from "./InvitationDetails.module.scss";
import { SidebarFooter } from "../Sidebar/SidebarFooter";
import { useFormContext } from "../UserForm/UserFormContext/UserFormContext";
import { getFormattedDate } from "../../../lib/date-formatter";
import LimitByRole from "../LimitByRole/LimitByRole";

interface IInvitationDetailsProps {
    onCancel: () => void;
    onRevoke?: ({ invitationId }: { invitationId: string }) => void;
    onResend?: ({ invitationId }: { invitationId: string }) => void;
}

export const InvitationDetails = ({ onCancel, onRevoke, onResend }: IInvitationDetailsProps) => {
    const { invitationState } = useFormContext();
    const { invitation } = invitationState;

    let action = "";
    let statusDate: Date | undefined;
    if (invitation) {
        if (invitation.status === INVITATION_STATUS.PENDING) {
            action = "Revoke";
        } else if (invitation.status === INVITATION_STATUS.EXPIRED) {
            action = "Resend";
            statusDate = invitation.expiredDate;
        } else if (invitation.status === INVITATION_STATUS.ACCEPTED) {
            statusDate = invitation.acceptedDate;
        } else {
            statusDate = invitation.revokedDate;
        }
    }

    let statusType: "bad-news" | "good-news" | "warning" = "bad-news";
    if (invitation?.status === INVITATION_STATUS.PENDING) statusType = "warning";
    if (invitation?.status === INVITATION_STATUS.ACCEPTED) statusType = "good-news";

    return (
        <form className="flex flex--column user-form">
            <div className={styles["invitation-details__content"]}>
                {/* since we have so many conditional renders dependent on existing invitation state, I'm planning on refactoring this out the new invitation form to its own component - AZ */}
                <Attention
                    alignment="left"
                    className="push--top push-double--bottom push-quad--sides"
                    type={statusType}
                >
                    <span>
                        <strong>Status:</strong> {invitation?.status}
                        {statusDate &&
                            ` - ${getFormattedDate({
                                date: statusDate,
                                hideTimestamp: true
                            })}`}
                    </span>
                </Attention>

                <div className={`push-quad--bottom soft-quad--sides`}>
                    <>
                        <label className="oui-label">First Name</label>
                        <Typography type="body" className="label--disabled">
                            {invitation?.firstName}
                        </Typography>
                    </>
                </div>

                <div className={`push-quad--bottom soft-quad--sides`}>
                    <>
                        <label className="oui-label">Last Name</label>
                        <Typography type="body" className="label--disabled">
                            {invitation?.lastName}
                        </Typography>
                    </>
                </div>

                <div className={`push-quad--bottom soft-quad--sides`}>
                    <>
                        <label className="oui-label">Email</label>
                        <Typography type="body" className="label--disabled">
                            {invitation?.email}
                        </Typography>
                    </>
                </div>

                {invitation && (
                    <div className="push-quad--bottom soft-quad--sides">
                        <span className="oui-label push--top">Groups</span>
                        {invitation.userGroups.map((userGroup) => {
                            return (
                                <div key={userGroup.id}>
                                    <Typography type="body" className="label--disabled">
                                        {userGroup.name}
                                    </Typography>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>

            <SidebarFooter onCancel={onCancel}>
                <>
                    {action === "Revoke" && invitation?.id && (
                        <LimitByRole mode="hide" action={ATTRIBUTE_ROLES.INVITATIONS.UPDATE}>
                            <Button
                                key="revoke-user-button"
                                style="danger"
                                onClick={async () => {
                                    onRevoke && onRevoke({ invitationId: invitation.id! });
                                }}
                            >
                                {action}
                            </Button>
                        </LimitByRole>
                    )}

                    {action === "Resend" && invitation?.id && (
                        <LimitByRole mode="hide" action={ATTRIBUTE_ROLES.INVITATIONS.UPDATE}>
                            <Button
                                key="resend-user-button"
                                style="highlight"
                                onClick={async () => {
                                    onResend && onResend({ invitationId: invitation.id! });
                                }}
                            >
                                {action}
                            </Button>
                        </LimitByRole>
                    )}
                </>
            </SidebarFooter>
        </form>
    );
};

InvitationDetails.displayName = "InvitationDetails";
