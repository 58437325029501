import { Collection } from "../../domain/Collection";

import { Role, RoleCreationParameters } from "../../domain/Role";
import { useServicesContext } from "../../providers/ServicesProvider";
import { usePaginatedList } from "../usePaginatedList/usePaginatedList";
import { DEFAULT_PAGE_SIZE, SWR_KEY_PREFIX, EVENT_TYPES } from "../../constants/constants";
import { useEffect } from "react";
import { datadogRum } from "@datadog/browser-rum";
import { useUpdateSync } from "../useUpdateSync/useUpdateSync";

export const useRoles = ({
    organizationId,
    productId = "",
    instanceId = "",
    searchQuery = "",
    size = DEFAULT_PAGE_SIZE
}: {
    organizationId?: string;
    productId?: string;
    instanceId?: string;
    searchQuery?: string;
    size?: number;
}) => {
    const { userService } = useServicesContext();
    const { fireChangeEvent } = useUpdateSync();

    const {
        pageData,
        isLoading: paginationLoading,
        error,
        currentPage,
        setCurrentPage,
        mutate
    } = usePaginatedList<Role>({
        key: organizationId
            ? `${SWR_KEY_PREFIX.ROLES}/api/organizations/${organizationId}/roles/search?searchQuery=${searchQuery}&productId=${productId}&instanceId=${instanceId}&size=${size}`
            : null,
        fetcher: (): Promise<Collection<Role>> => {
            return userService.searchRoles({
                organizationId: organizationId as string,
                productId,
                instanceId,
                searchQuery,
                size,
                page: currentPage
            });
        }
    });

    useEffect(() => {
        if (error) {
            datadogRum.addError(error);
        }
    }, [error]);

    const createRole = async (roleDetails: RoleCreationParameters) => {
        const role = await userService.createRole(roleDetails);

        fireChangeEvent({
            type: EVENT_TYPES.ADD_ROLE,
            data: {
                role: { id: roleDetails.id || "", name: roleDetails.name, description: roleDetails.description || "" }
            }
        });
        return role;
    };

    const updateRole = async (roleDetails: RoleCreationParameters) => {
        const result = await userService.updateRole(roleDetails);

        if (roleDetails.id) {
            fireChangeEvent({
                type: EVENT_TYPES.UPDATE_ROLE,
                data: {
                    role: { id: roleDetails.id, name: roleDetails.name, description: roleDetails.description || "" }
                }
            });
        }

        return result;
    };

    const deleteRole = async ({ roleId }: { roleId: string }) => {
        const response = await userService.deleteRole({ roleId });

        fireChangeEvent({
            type: EVENT_TYPES.DELETE_ROLE,
            data: {
                role: { id: roleId, name: "", description: "" }
            }
        });

        return response;
    };

    return {
        createRole,
        deleteRole,
        roles: pageData?.items,
        isLoading: paginationLoading || (!error && !pageData),
        error,
        currentPage,
        setCurrentPage,
        pageSize: DEFAULT_PAGE_SIZE,
        totalCount: pageData?.totalCount || 0,
        updateRole,
        revalidate: mutate
    };
};
