import { Role } from "./Role";

export class UserInstancePermission {
    constructor(instancePermissions: any) {
        this.created = instancePermissions.Created;
        this.instanceId = instancePermissions.InstanceId;
        this.instanceName = instancePermissions.InstanceName;
        this.navigationUrl = instancePermissions.NavigationUrl;
        this.region = instancePermissions.Region;
        this.groupName = instancePermissions.GroupName;
        this.groupId = instancePermissions.GroupId;
        this.nickname = instancePermissions.InstanceNickname;
        this.productId = instancePermissions.ProductId;
        this.productName = instancePermissions.ProductName;
        this.productType = instancePermissions.ProductType;
        this.organizationName = instancePermissions.OrganizationName;
        this.organizationId = instancePermissions.OrganizationId;
        this.roles = instancePermissions.Roles?.map((role: any) => new Role(role));
    }
    created?: string;
    instanceId: string;
    instanceName: string;
    navigationUrl?: string;
    nickname?: string;
    region: string;
    groupName: string;
    groupId: string;
    productId: string;
    productName: string;
    productType: string;
    organizationName: string;
    organizationId: string;
    roles: any[];
}
