import { BaseFieldProps } from "./BaseFieldProps";
import React from "react";
import { handleFieldChange } from "./oidc-form-field-helpers";

export const TokenURLField = ({ disabled, onChange, value = "" }: BaseFieldProps) => {
    return (
        <>
            <label className="oui-label push-double--top" htmlFor="token">
                Token URL
            </label>
            <input
                aria-describedby="token-error"
                className="oui-text-input"
                disabled={disabled}
                id="token"
                type="text"
                value={value}
                onChange={(e) => handleFieldChange({ e, onChange })}
                placeholder="Token URL"
            />
            <span className="oui-form-note" id="token-description">
                URL of the OAuth 2.0 Token Endpoint
            </span>
        </>
    );
};

TokenURLField.displayName = "TokenURLField";
