import React from "react";
import { Navigate } from "react-router-dom";

import { useUserContext } from "../../../providers/UserProvider";
import { Unauthorized } from "../../pages/Unauthorized/Unauthorized";

const DetermineAuthorizedRoute = ({ routesList }: { routesList: any[] | undefined }): React.ReactElement | null => {
    if (!routesList?.length) {
        return <Unauthorized />;
    }

    const view = routesList[0];
    if (view.path) {
        return <Navigate to={view.path} replace />;
    }

    routesList.shift();
    return DetermineAuthorizedRoute({ routesList });
};

export const NavigateToAuthorized = () => {
    const { getAvailableRoutes } = useUserContext();
    const availableRoutes = getAvailableRoutes();

    return DetermineAuthorizedRoute({ routesList: availableRoutes });
};

NavigateToAuthorized.displayName = "NavigateToAuthorized";
