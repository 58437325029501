import { useFeatureFlag } from "../../../hooks/useFeatureFlag/useFeatureFlag";
import { Flags } from "../../../feature-flags/flags";

type FeatureFlagSwitchProps = {
    featureFlag: Flags;
    onElement: JSX.Element;
    offElement: JSX.Element;
};

export const FeatureFlagSwitch = ({ featureFlag, onElement, offElement }: FeatureFlagSwitchProps) => {
    const flagDecision = useFeatureFlag(featureFlag || ("" as Flags));

    const isFeatureEnabled = flagDecision?.enabled;

    if (isFeatureEnabled) {
        return onElement;
    } else {
        return offElement;
    }
};

FeatureFlagSwitch.displayName = "FeatureFlagSwitch";
