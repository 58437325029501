import { IIdentityRepository } from "../../repositories/IdentityRepository";
import { LoginSettings } from "../../domain/LoginSettings";
import {
    IOrganizationSettings,
    OrganizationSettings as RestOrganizationSettings
} from "../../rest-model/OrganizationSettings";

export interface IOrganizationSettingsService {
    getOrganizationSettings({ organizationId }: { organizationId: string }): Promise<IOrganizationSettings>;
    getLocalLoginSettings({ organizationId }: { organizationId: string }): Promise<LoginSettings>;
    updateGenAISettings({
        organizationId,
        settings
    }: {
        organizationId: string;
        settings: { enabled: boolean };
    }): Promise<{ enabled: boolean }>;
    updateLocalLoginSettings({
        organizationId,
        settings
    }: {
        organizationId: string;
        settings: LoginSettings;
    }): Promise<LoginSettings>;
    updateOrganizationSettings({
        organizationId,
        updates
    }: {
        organizationId: string;
        updates: Partial<RestOrganizationSettings>;
    }): Promise<RestOrganizationSettings>;
}

export class OrganizationSettingsService implements IOrganizationSettingsService {
    identityRepository: IIdentityRepository;

    constructor(identityRepository: IIdentityRepository) {
        this.identityRepository = identityRepository;
    }

    public async getLocalLoginSettings({ organizationId }: { organizationId: string }): Promise<LoginSettings> {
        const result = await this.identityRepository.getLocalLoginSettings({ organizationId });
        return new LoginSettings(result.Settings);
    }

    public async getOrganizationSettings({
        organizationId
    }: {
        organizationId: string;
    }): Promise<RestOrganizationSettings> {
        const result = await this.identityRepository.getOrganizationSettings({ organizationId });
        return new RestOrganizationSettings(result);
    }

    public async updateGenAISettings({
        organizationId,
        settings
    }: {
        organizationId: string;
        settings: { enabled: boolean };
    }) {
        return await this.identityRepository.updateGenAISettings({ organizationId, settings });
    }

    public async updateOrganizationSettings({
        organizationId,
        updates
    }: {
        organizationId: string;
        updates: Partial<RestOrganizationSettings>;
    }): Promise<RestOrganizationSettings> {
        const result = await this.identityRepository.updateOrganizationSettings({
            organizationId,
            updates
        });
        return new RestOrganizationSettings(result);
    }

    public async updateLocalLoginSettings({
        organizationId,
        settings
    }: {
        organizationId: string;
        settings: LoginSettings;
    }): Promise<LoginSettings> {
        const adaptedSettings = {
            enabled: settings?.localLoginEnabled,
            mfaEnabled: settings?.mfaEnabled
        };

        const result = await this.identityRepository.updateLocalLoginSettings({
            organizationId,
            settings: adaptedSettings
        });
        return new LoginSettings(result);
    }
}
