import { useCallback, useEffect, useMemo } from "react";
import { useSWRConfig } from "swr";
import { ADMINCENTER_ITEM_UPDATED_EVENT_NAME, EVENT_TYPES } from "../../../constants/constants";
import {
    addGroupToUser,
    addUserToGroup,
    ChangeEventData,
    DataList,
    invalidateGroup,
    invalidateList,
    invalidateUserGroupsForUser,
    invalidateUsersForGroup,
    removeGroup,
    removeGroupFromUser,
    removeInstanceFromGroup,
    removeRole,
    removeUserFromAllGroups,
    removeUserFromGroup,
    updateGroup,
    updateInstance,
    updateRole,
    updateUser
} from "../../../lib/sync-updaters";

export const UpdateSynchronizer = () => {
    const { cache, mutate } = useSWRConfig();

    const eventActionMapping = useMemo<{
        [key: string]: {
            keyPrefix: string;
            action: (eventData: ChangeEventData, list: DataList, key?: string) => DataList | undefined;
        }[];
    }>(() => {
        const mapping: {
            [key: string]: {
                keyPrefix: string;
                action: (eventData: ChangeEventData, list: DataList, key?: string) => DataList | undefined;
            }[];
        } = {};

        mapping[EVENT_TYPES.UPDATE_USER] = [{ keyPrefix: "list-of-users", action: updateUser }];
        mapping[EVENT_TYPES.UPDATE_INSTANCE] = [{ keyPrefix: "list-of-instances", action: updateInstance }];
        mapping[EVENT_TYPES.UPDATE_GROUP] = [{ keyPrefix: "list-of-groups", action: updateGroup }];
        mapping[EVENT_TYPES.UPDATE_ROLE] = [{ keyPrefix: "list-of-roles", action: updateRole }];

        mapping[EVENT_TYPES.ADD_USER] = [{ keyPrefix: "list-of-users", action: invalidateList }];
        mapping[EVENT_TYPES.ADD_GROUP] = [{ keyPrefix: "list-of-groups", action: invalidateList }];
        mapping[EVENT_TYPES.ADD_ROLE] = [{ keyPrefix: "list-of-roles", action: invalidateList }];

        mapping[EVENT_TYPES.DELETE_ROLE] = [{ keyPrefix: "list-of-roles", action: removeRole }];
        mapping[EVENT_TYPES.DELETE_GROUP] = [
            { keyPrefix: "list-of-groups", action: removeGroup },
            { keyPrefix: "list-of-users", action: removeGroupFromUser }
        ];
        mapping[EVENT_TYPES.DELETE_USER] = [
            { keyPrefix: "list-of-groups", action: removeUserFromAllGroups },
            { keyPrefix: "list-of-users", action: invalidateList }
        ];

        mapping[EVENT_TYPES.ADD_USER_TO_GROUP] = [
            { keyPrefix: "list-of-groups", action: addUserToGroup },
            { keyPrefix: "list-of-users", action: addGroupToUser },
            { keyPrefix: "list-of-users", action: invalidateUsersForGroup },
            { keyPrefix: "list-of-groups", action: invalidateUserGroupsForUser }
        ];
        mapping[EVENT_TYPES.REMOVE_USER_FROM_GROUP] = [
            { keyPrefix: "list-of-groups", action: removeUserFromGroup },
            { keyPrefix: "list-of-users", action: removeGroupFromUser },
            { keyPrefix: "list-of-users", action: invalidateUsersForGroup },
            { keyPrefix: "list-of-groups", action: invalidateUserGroupsForUser }
        ];

        mapping[EVENT_TYPES.ADD_INSTANCE_TO_GROUP] = [
            { keyPrefix: "list-of-groups", action: invalidateGroup },
            { keyPrefix: "list-of-users", action: invalidateList }
        ];
        mapping[EVENT_TYPES.REMOVE_INSTANCE_FROM_GROUP] = [
            { keyPrefix: "list-of-groups", action: removeInstanceFromGroup },
            { keyPrefix: "list-of-users", action: invalidateList }
        ];

        return mapping;
    }, []);

    const handleUpdateSyncEvent = useCallback(
        (e: Event): void => {
            const { type, data: eventData } = (e as CustomEvent).detail;

            const cacheKeys = Array.from((cache as Map<string, any>).keys());
            const eventAction = eventActionMapping[type];

            eventAction.forEach(({ keyPrefix, action }) => {
                const relevantKeys = cacheKeys.filter((k) => {
                    const matches = k.match(/"(.*?)"/);
                    const [prefix] = (matches ? matches[1] : k).split(":");

                    return prefix === keyPrefix;
                });

                relevantKeys.forEach((key) => {
                    const updaterForKey = (existingData: any) => action(eventData, existingData, key);

                    if (key?.includes("refresh=1")) {
                        mutate(key, undefined, { revalidate: true });
                    } else {
                        const updatedData = updaterForKey(cache.get(key));
                        mutate(key, updatedData, {
                            revalidate: !updatedData
                        });
                    }
                });
            });
        },
        [cache, mutate, eventActionMapping]
    );

    useEffect(() => {
        window.addEventListener(
            ADMINCENTER_ITEM_UPDATED_EVENT_NAME,
            handleUpdateSyncEvent as EventListenerOrEventListenerObject
        );

        return () => {
            window.removeEventListener(
                ADMINCENTER_ITEM_UPDATED_EVENT_NAME,
                handleUpdateSyncEvent as EventListenerOrEventListenerObject
            );
        };
    }, [handleUpdateSyncEvent]);
    return <></>;
};
