import { Invitation as RestInvitation } from "../rest-model/Invitation";
import { Invitation } from "../domain/Invitation";
import { INVITATION_STATUS } from "../constants/constants";

export class InvitationAdapter {
    public adaptToDomain({
        acceptedDate,
        expirationDate,
        revokedDate,
        created,
        modified,
        sentDates,
        reasonForAccess,
        ...rest
    }: RestInvitation): Invitation {
        const expirationStatus = expirationDate && new Date().getTime() > new Date(expirationDate).getTime();

        const status = !!acceptedDate
            ? INVITATION_STATUS.ACCEPTED
            : !!revokedDate
            ? INVITATION_STATUS.REVOKED
            : !!expirationStatus
            ? INVITATION_STATUS.EXPIRED
            : INVITATION_STATUS.PENDING;

        return new Invitation({
            created: new Date(created),
            modified: new Date(modified),
            expiredDate: expirationDate ? new Date(expirationDate) : undefined,
            revokedDate: revokedDate ? new Date(revokedDate) : undefined,
            acceptedDate: acceptedDate ? new Date(acceptedDate) : undefined,
            reasonForAccess: reasonForAccess,
            status,
            sentDates: sentDates?.map((sentDate) => new Date(sentDate)),
            ...rest
        });
    }

    public adaptToRest(newInvitation: Invitation): RestInvitation {
        const {
            firstName: FirstName,
            lastName: LastName,
            email: Email,
            organizationId: OrganizationId,
            userGroups: UserGroups,
            createdBy: CreatedBy,
            requireAcceptance: RequireAcceptance,
            sentDates: SentDates,
            reasonForAccess: ReasonForAccess
        } = newInvitation;

        return new RestInvitation({
            CreatedBy,
            FirstName,
            LastName,
            Email,
            OrganizationId,
            ReasonForAccess,
            UserGroups: UserGroups
                ? UserGroups.map((ug) => {
                      return { Id: ug.id, Name: ug.name };
                  })
                : [],
            RequireAcceptance,
            SentDates
        });
    }
}
