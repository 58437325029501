import { Checkbox, Typography } from "@optimizely/axiom";
import { ProductAttribute } from "../../../domain/ProductAttribute";
import { RoleAttribute } from "../../../domain/RoleAttribute.interface";

type TeamAssociationCheckboxProps = {
    cmpTeamAttribute: ProductAttribute;
    cmpTeamAttributeEnabled: boolean;
    onChange: ({
        preMappedValues,
        id,
        key,
        values
    }: {
        id?: string;
        key?: string;
        preMappedValues?: boolean;
        values: string[] | RoleAttribute[];
    }) => void;
};

export const TeamAssociationCheckbox = ({
    cmpTeamAttribute,
    onChange,
    cmpTeamAttributeEnabled
}: TeamAssociationCheckboxProps) => {
    const { id, key, values, description } = cmpTeamAttribute;
    return (
        <div className="push-triple--top">
            <Typography type="header4">
                <span style={{ fontWeight: 300 }}>Team Creation</span>
            </Typography>
            <div className="push--bottom">
                <Typography type="body">{description}</Typography>
            </div>
            <Checkbox
                checked={cmpTeamAttributeEnabled}
                onChange={() => onChange({ id, key, values })}
                label="Enabled"
            />
        </div>
    );
};

TeamAssociationCheckbox.displayName = "TeamAssociationCheckbox";
