import { useFeatureFlag } from "../useFeatureFlag/useFeatureFlag";
import { Flags } from "../../feature-flags/flags";

export interface CsmOrgsVariable {
    organizations: [
        {
            id: string;
            name: string;
        }
    ];
}

export const useCsmOrganizations = () => {
    const { enabled: hideFromSupport, variables: hiddenOrgs } = useFeatureFlag(Flags.HIDE_SUPPORT_ORGANIZATIONS);
    const { enabled: restrict, variables: restrictedOrgs } = useFeatureFlag(Flags.RESTRICTED_SUPPORT_ORGS);

    const csmOrgsToHide: CsmOrgsVariable = hiddenOrgs.csm_orgs as CsmOrgsVariable;
    const supportOnlyOrgs: CsmOrgsVariable = restrictedOrgs.restricted_support_orgs as CsmOrgsVariable;

    return {
        hideFromSupportTableOrgs: hideFromSupport ? csmOrgsToHide.organizations : [],
        restrictedSupportOrgs: restrict ? supportOnlyOrgs.organizations : []
    };
};
