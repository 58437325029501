import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { Flags } from "../../../../feature-flags/flags";
import { useFeatureFlag } from "../../../../hooks/useFeatureFlag/useFeatureFlag";

export const UserManagementRouting = () => {
    const { enabled: showRolesSidebarNav } = useFeatureFlag(Flags.SHOW_ROLES_SIDEBAR_NAVIGATION);

    const { pathname } = useLocation();

    // when feature flags are no longer used for view logic, remove routes in <App />
    const unregisterRolesRoute =
        showRolesSidebarNav && (pathname === "/user-management/roles" || pathname === "/user-management/roles/");

    if (unregisterRolesRoute) {
        return <Navigate to="/404" replace />;
    }

    return <Outlet />;
};
