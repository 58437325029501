import React, { useEffect, useState } from "react";
import { useProducts } from "../../../../hooks/useProducts/useProducts";
import { useUserContext } from "../../../../providers/UserProvider";
import { TableSkeleton } from "../../Skeleton/TableSkeleton";
import { useAccessList } from "../../../../hooks/useAccessList/useAccessList";
import { AccessDetailsTable } from "../AccessDetailsTable/AccessDetailsTable";
import { getAccessByProduct, updateAccessWithProjectDetails } from "../../../../lib/access-helpers";
import {
    CustomGroupAccess,
    useAccessFlowUserFormContext
} from "../AccessManagementUserForm/AccessManagementUserFormProvider";
import { AccessDetailsByProductId } from "../../../../domain/AccessDetails";
import { UserGroup } from "../../../../domain/UserGroup";

type CustomGroupAccessDetailsProps = {
    userGroup: UserGroup;
};

export const CustomGroupAccessDetails = ({ userGroup }: CustomGroupAccessDetailsProps) => {
    const { organizationId = "" } = useUserContext();
    const { getUserGroupInstances } = useProducts({ organizationId });
    const { instanceGroups: availableInstanceAccess } = useAccessList({
        organizationId,
        instanceIds: userGroup.instancePermissions.map((ip) => ip.instanceId)
    });
    const [productList, setProductList] = useState<AccessDetailsByProductId>({});
    const [accessDetails, setAccessDetails] = useState<AccessDetailsByProductId>({});
    const [groupDetailsLoading, setGroupDetailsLoading] = useState(true);
    const { setUpdateCustomGroupAccess } = useAccessFlowUserFormContext();

    useEffect(() => {
        if (!!userGroup.id) {
            getUserGroupInstances({ userGroupId: userGroup.id })
                .then((response) => {
                    const productInstances = response.items;
                    const accessByProduct = getAccessByProduct({
                        productInstances,
                        userGroup,
                        isCustomGroup: true
                    });
                    setProductList(accessByProduct || {});
                })

                .catch((error) => console.error("Failed to fetch product instances: ", error))
                .finally(() => setGroupDetailsLoading(false));
        }
    }, [userGroup, getUserGroupInstances]);

    useEffect(() => {
        const isProductListEmpty = Object.keys(productList).length === 0;
        let accessDetails = { ...productList };

        const hasExperimentationProductInGroupAccess =
            !isProductListEmpty && productList[process.env.REACT_APP_EXPERIMENTATION_PRODUCT_ID!];

        const hasAvailableOrgAccess = (availableInstanceAccess || []).length > 0;

        const needsProjectDetails = hasExperimentationProductInGroupAccess && hasAvailableOrgAccess && !!userGroup.id;

        if (needsProjectDetails) {
            setGroupDetailsLoading(true);
            accessDetails = updateAccessWithProjectDetails({
                productList,
                availableOrgAccess: availableInstanceAccess,
                groupId: userGroup.id
            });
            setGroupDetailsLoading(false);
        }

        setAccessDetails(accessDetails);
        setUpdateCustomGroupAccess((prev): CustomGroupAccess => {
            return { ...prev, accessDetails };
        });
    }, [productList, userGroup.id, setUpdateCustomGroupAccess, availableInstanceAccess]);

    if (groupDetailsLoading) {
        return <TableSkeleton className="push-double--top push-double--bottom push--left" />;
    }

    return <AccessDetailsTable accessDetails={accessDetails} />;
};

CustomGroupAccessDetails.displayName = "CustomGroupAccessDetails";
