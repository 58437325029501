import { useLocation, useNavigate } from "react-router-dom";
import { Sidebar } from "@optimizely/axiom";
import classNames from "classnames";

import styles from "./NavigationBar.module.scss";
import { useUserContext } from "../../../providers/UserProvider";
import classnames from "classnames";
import { getSecondaryNavigation } from "../../../lib/navigation-helpers";
import { NavigationBarMenuItem } from "./NavigationBarMenuItem";
import { useCsmOrganizations } from "../../../hooks/useCsmOrganizations/useCsmOrganizations";
import { IMenuItem, IRoute } from "../../../lib/navigation-types";

export const NavigationBar = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { getAvailableRoutes, organizationId } = useUserContext();
    const { restrictedSupportOrgs } = useCsmOrganizations();

    const availableRoutes = getAvailableRoutes();

    const navigationTabs: any = availableRoutes
        ?.filter((r: IRoute) => {
            if (r.menuItem) {
                const isSupportOrg = restrictedSupportOrgs
                    ? restrictedSupportOrgs.find((o) => o.id === organizationId)
                    : false;

                return isSupportOrg ? r.menuItem.support : true;
            } else {
                return false;
            }
        })
        ?.map((route: IRoute) => {
            return {
                ...route.menuItem,
                isActive: route.path ? pathname.startsWith(route.menuItem?.href || "") : false
            };
        })
        .sort((a, b) => {
            return (a?.listOrder || 0) - (b?.listOrder || 0);
        });

    const hasSecondaryNavigation = !!getSecondaryNavigation({ path: pathname });

    const menuItemClasses = classnames(styles["navigation-bar__menu-item"], {
        [styles["navigation-bar__menu-item--collapsed"]]: hasSecondaryNavigation
    });

    return (
        <Sidebar
            className={styles["navigation-bar"]}
            anchor="left"
            border
            borderColor="#E8E8E8"
            backgroundColor="smoke"
            isOpen={!hasSecondaryNavigation} //TODO: enable this as part of https://jira.sso.episerver.net/browse/CSX-1281 (missing the pop-tips)
            collapsedWidth={60}
            isFullHeight
            width={208}
            docked
            sticky
        >
            <div className={classNames("soft-half--bottom", styles["navigation-bar__main"])}>
                {navigationTabs?.map((item: IMenuItem, index: number) => {
                    return (
                        <NavigationBarMenuItem
                            key={index}
                            {...item}
                            className={menuItemClasses}
                            showTooltip={hasSecondaryNavigation}
                            onClick={() => {
                                // Prop doesn't support args, so cannot use e.preventDefault(), but this also works
                                window.event?.preventDefault();
                                if (item?.href) navigate(item.href);
                            }}
                        />
                    );
                })}
            </div>
        </Sidebar>
    );
};

NavigationBar.displayName = "NavigationBar";
