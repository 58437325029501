import { IAccessContext } from "../../services/UserService";
import { useEffect, useState } from "react";
import { Role } from "../../domain/Role";
import { useContextUserGroups } from "../useContextUserGroups/useContextUserGroups";
import { CONTEXT_SCOPES } from "../../constants/constants";

//gets roles for the organization provided and with the groups that match the given context
export const useContextRoles = ({ organizationId, context }: { organizationId?: string; context?: IAccessContext }) => {
    const [roles, setRoles] = useState<Role[]>([]);

    const { groups, isLoading, error } = useContextUserGroups({ organizationId, context });

    useEffect(() => {
        if (groups?.length) {
            const roleList = groups
                ?.flatMap((group) => group.instancePermissions)
                .filter((permission) =>
                    context?.scope === CONTEXT_SCOPES.INSTANCE ? permission.instanceId === context?.items[0]?.id : true
                )
                .flatMap((permission) => permission.roles);

            const uniqueRoles = roleList?.length
                ? Array.from(new Map(roleList.map((item) => [item["id"], item])).values())
                : [];

            setRoles(uniqueRoles);
        }
    }, [groups, context]);

    return {
        roles,
        isLoading,
        error
    };
};
