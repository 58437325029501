import { Attention, Spinner, Switch, Typography } from "@optimizely/axiom";
import { useEffect, useState } from "react";
import styles from "./GenAIPage.module.scss";
import { ConfirmationDialog } from "../../../components/ConfirmationDialog/ConfirmationDialog";
import { emitToast } from "../../../../lib/toaster-utils";
import { useAnalyticsTracking } from "../../../../hooks/useAnalyticsTracking/useAnalyticsTracking";
import {
    ANALYTICS_EVENT_NAMES,
    ANALYTICS_FLOWS,
    ANALYTICS_TRACKED_COMPONENTS
} from "../../../../constants/analytics-constants";
import { useUserContext } from "../../../../providers/UserProvider";
import { useOrganizationSettings } from "../../../../hooks/useOrganizationSettings/useOrganizationSettings";
import { UNEXPECTED_ERROR } from "../../../../constants/constants";
import { datadogRum } from "@datadog/browser-rum";

export const GenAIPage = () => {
    const { sendTrackEvent } = useAnalyticsTracking();
    const { organizationId } = useUserContext();
    const { settings, updateGenAISettings, loading: settingsLoading } = useOrganizationSettings({ organizationId });
    const [loading, setLoading] = useState(false);
    const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
    const [error, setError] = useState("");
    const [organizationSetting, setOrganizationSetting] = useState(settings?.genAI?.enabled);

    useEffect(() => {
        if (settings) {
            setOrganizationSetting(settings.genAI.enabled);
        }
    }, [settings]);

    const switchGenAIOff = () => {
        setLoading(true);
        setError("");
        updateGenAISettings({
            enabled: false
        })
            .then(() => {
                setShowCancelConfirmation(false);
                setOrganizationSetting(false);
                emitToast({ message: "Gen AI settings successfully updated." });
                sendTrackEvent({
                    name: ANALYTICS_EVENT_NAMES.GEN_AI_UPDATE,
                    component: ANALYTICS_TRACKED_COMPONENTS.GEN_AI_TAB,
                    flow: ANALYTICS_FLOWS.SETTINGS,
                    data: {
                        status: "Disabled",
                        organizationId
                    }
                });
            })
            .catch((err: any) => {
                console.error(err);
                datadogRum.addError(error);
                setError(err.message || UNEXPECTED_ERROR);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const closeConfirmation = () => {
        setError("");
        setShowCancelConfirmation(false);
    };

    return (
        <div className={"push-double  width--3-5 " + styles["gen-ai"]}>
            <Typography type="header2" className="flex justify-content-between push-triple--bottom">
                Enable Gen AI
                <div className="flex align-items-center">
                    {(loading || settingsLoading) && <Spinner />}
                    {!settingsLoading && (
                        <Switch
                            elementId={"genai-toggle"}
                            isDisabled={!organizationSetting}
                            checked={organizationSetting}
                            onClick={() => setShowCancelConfirmation(true)}
                        />
                    )}
                </div>
            </Typography>
            {!(organizationSetting || settingsLoading) && (
                <Attention type="warning" className="push-triple--bottom">
                    <span className="weight--bold">Gen AI cannot be enabled here.</span> In order to enable Gen AI
                    features, please{" "}
                    <a rel="noreferrer" href="https://support.optimizely.com/hc/en-us/requests/new" target="_blank">
                        submit a support ticket.
                    </a>
                </Attention>
            )}
            {showCancelConfirmation && (
                <ConfirmationDialog
                    onCancel={closeConfirmation}
                    onConfirmation={switchGenAIOff}
                    loading={loading}
                    title="Disable Gen AI"
                >
                    {error && (
                        <Attention type="bad-news" className="push--bottom">
                            {error}
                        </Attention>
                    )}
                    This will disable Gen AI features across all Optimizely products for your organization. This cannot
                    be undone.
                </ConfirmationDialog>
            )}

            <Typography type="body">
                This setting allows you to manage the use of Gen AI features at the organizational level. By default,
                the setting is <span className="weight--bold">On</span> (Opt-in).
            </Typography>
            <ul className={styles["gen-ai__list"]}>
                <li>
                    <Typography type="body">
                        <span className="weight--bold">On</span>: Enables AI-powered content and features across all
                        Optimizely applications and within Opti ID, delivering a personalized and tailored user
                        experience.
                    </Typography>
                </li>
                <li>
                    <Typography type="body">
                        <span className="weight--bold">Off</span>: Disables AI-generated personalization and other Gen
                        AI features across all Optimizely applications and within Opti ID.
                    </Typography>
                </li>
            </ul>
            <Typography type="body">
                Adjust this setting to align with your organization’s preferences and requirements for AI-driven
                experiences.
            </Typography>
        </div>
    );
};

GenAIPage.displayName = "GenAIPage";
