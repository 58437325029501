import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";

import { useProducts } from "../../../hooks/useProducts/useProducts";
import { useUserGroups } from "../../../hooks/useUserGroups/useUserGroups";
import { useAttributes } from "../../../hooks/useAttributes/useAttributes";

import { ProductPermission } from "../../../domain/Permission.interface";
import { ProductAttribute } from "../../../domain/ProductAttribute";
import { IOrganizationProductInstance } from "../../../domain/OrganizationProduct";
import { Role } from "../../../domain/Role";

import { ALL_PAGES_SIZE, ALL_PROJECTS_ID, ALL_PROJECTS_NAME, GROUP_TYPES } from "../../../constants/constants";

import {
    getOrgPermissionsFromGroupList,
    getProjects,
    orgPermissionListToAccessList
} from "../../../services/AccessListTransforms";

import { ProductDropdown } from "../ProductDropdown/ProductDropdown";
import { InstanceDropdown } from "../InstanceDropdown/InstanceDropdown";
import { ProjectsDropdown } from "../ProjectsDropdown/ProjectsDropdown";
import { RoleSelector } from "../RoleSelector/RoleSelector";

import styles from "./AccessSelections.module.scss";
import classnames from "classnames";
import { SelectedUserGroupAccess } from "../../../domain/SelectedUserGroupAccess";

type AccessSelectionsProps = {
    organizationId: string;
    onRoleSelect: Dispatch<SetStateAction<SelectedUserGroupAccess | undefined>>;
};

export const AccessSelections = ({ organizationId, onRoleSelect }: AccessSelectionsProps) => {
    const { products = [], instances = [] } = useProducts({ organizationId });

    const [selectedProductId, setSelectedProductId] = useState("");
    const [selectedInstanceId, setSelectedInstanceId] = useState("");
    const [selectedProjectId, setSelectedProjectId] = useState<string | undefined>(undefined);
    const [selectedRole, setSelectedRole] = useState<Role | undefined>(undefined);
    const [availableGroupRoles, setAvailableGroupRoles] = useState<ProductPermission[] | undefined>(undefined);

    const { userGroups, isLoading } = useUserGroups({
        organizationId: !!selectedInstanceId ? organizationId : undefined,
        groupTypes: [GROUP_TYPES.PRODUCT],
        pageSize: ALL_PAGES_SIZE,
        instanceIds: !!selectedInstanceId ? [selectedInstanceId] : []
    });

    const { getExperimentationProjects, isLoadingInitialData } = useAttributes({});

    useEffect(() => {
        if (userGroups && products.length) {
            const orgPermissions = getOrgPermissionsFromGroupList(userGroups, products);
            const promiseList = getProjects(orgPermissions, getExperimentationProjects);
            Promise.all(promiseList).then(() => {
                const list = orgPermissionListToAccessList(orgPermissions);
                if (list) setAvailableGroupRoles(list);
            });
        }
    }, [userGroups, products, getExperimentationProjects]);

    const isExp = selectedProductId === process.env.REACT_APP_EXPERIMENTATION_PRODUCT_ID;

    const roleList = useMemo(() => {
        return (
            availableGroupRoles
                ?.flatMap((gr) => gr.instanceAccess.flatMap((i: any) => i.availableRoles))
                .filter(
                    (i) =>
                        !i.role.isInstanceRole &&
                        i.group.instancePermissions.some((p: any) => p.instanceId === selectedInstanceId)
                ) || []
        );
    }, [availableGroupRoles, selectedInstanceId]);

    const projectList = useMemo(() => {
        const projects = isExp ? roleList?.filter((role) => !!role.project).map((r) => r.project) : [];
        return Array.from(new Map(projects.map((item) => [item["key"], item])).values());
    }, [isExp, roleList]);

    const updatedProjectList = useMemo(() => {
        const allProjectsOption = {
            id: ALL_PROJECTS_ID,
            name: ALL_PROJECTS_NAME,
            description: "",
            instanceId: "",
            isActive: true,
            key: "",
            type: "",
            values: []
        };
        return [allProjectsOption, ...projectList];
    }, [projectList]);

    const roles = useMemo(() => {
        return Array.from(
            new Set(
                roleList
                    ?.filter((role) => {
                        if (!isExp) return true;
                        return selectedProjectId === ALL_PROJECTS_ID
                            ? !role?.project
                            : role?.project?.id === selectedProjectId;
                    })
                    .map((r) => r?.role)
            )
        );
    }, [roleList, selectedProjectId, isExp]);

    const selectedProductInstances = useMemo(() => {
        return instances
            .filter((instance) => instance.productId === selectedProductId)
            .map(
                (instance) =>
                    ({
                        id: instance.instanceId,
                        name: instance.instanceName,
                        nickname: instance.nickname,
                        optiIdEnabled: instance.optiIdEnabled,
                        region: instance.region,
                        technicalContactEmail: instance.technicalContactEmail,
                        technicalContactName: instance.technicalContactName
                    }) as IOrganizationProductInstance
            );
    }, [instances, selectedProductId]);

    // Reset dropdown and role state when organizationId changes
    useEffect(() => {
        setSelectedProductId("");
        setSelectedInstanceId("");
        setSelectedProjectId(undefined);
        setSelectedRole(undefined);
        onRoleSelect(undefined);
        setAvailableGroupRoles(undefined);
    }, [organizationId, onRoleSelect]);

    // auto select single dropdown options (projects omitted)
    useEffect(() => {
        if (products.length === 1) {
            setSelectedProductId(products[0].id);
        }
    }, [products]);

    useEffect(() => {
        if (!!selectedProductId && selectedProductInstances.length === 1) {
            setSelectedInstanceId(selectedProductInstances[0].id);
        }
    }, [selectedProductInstances, selectedProductId]);

    useEffect(() => {
        if (!!selectedInstanceId && roles.length === 1) {
            const onlyRole = roles[0];
            setSelectedRole(onlyRole);

            const instance = instances.find((instance) => instance.instanceId === selectedInstanceId);
            const project = selectedProjectId && updatedProjectList.find((project) => project.id === selectedProjectId);
            const instanceName = instance?.nickname || instance?.instanceName;
            const productName = instance?.productName;
            const projectName = project?.name;
            const roleName = onlyRole.displayName || onlyRole.name;

            const [role] =
                roleList?.filter(
                    (i) =>
                        i.role.id === onlyRole.id &&
                        (selectedProjectId === ALL_PROJECTS_ID ? !i?.project : i?.project?.id === selectedProjectId)
                ) || [];
            !!role &&
                onRoleSelect({
                    id: role.group.id,
                    name: role.group.name,
                    productName,
                    instanceName,
                    projectName,
                    roleName
                });
        }
    }, [roles, onRoleSelect, roleList, selectedInstanceId, selectedProjectId, instances, updatedProjectList]);

    const resetRoleSelection = () => {
        if (!!selectedRole) {
            onRoleSelect(undefined);
            setSelectedRole(undefined);
        }
    };

    // dropdown selection handlers
    const handleProductChange = (e: { product: { id: string } }) => {
        setSelectedProductId(e.product.id);
        // reset dependent states
        setSelectedInstanceId("");
        setSelectedProjectId(undefined);
        resetRoleSelection();
    };

    const handleInstanceSelection = ({
        value
    }: {
        value: IOrganizationProductInstance | IOrganizationProductInstance[] | null;
    }) => {
        const instance = value as IOrganizationProductInstance;
        setSelectedInstanceId(instance?.id);
        // reset dependent states
        setSelectedProjectId(undefined);
        resetRoleSelection();
    };

    const handleProjectChange = ({ value }: { value: ProductAttribute | ProductAttribute[] | null }) => {
        const project = value as ProductAttribute;
        setSelectedProjectId(project.id);
        // reset dependent states
        resetRoleSelection();
    };

    const handleRoleChange = (e: Role) => {
        const instance = instances.find((instance) => instance.instanceId === selectedInstanceId);
        const project = selectedProjectId && updatedProjectList.find((project) => project.id === selectedProjectId);
        const instanceName = instance?.nickname || instance?.instanceName;
        const productName = instance?.productName;
        const projectName = project?.name;
        const roleName = e.displayName || e.name;

        const [role] =
            roleList?.filter(
                (i) =>
                    i.role.id === e.id &&
                    (selectedProjectId === ALL_PROJECTS_ID ? !i?.project : i?.project?.id === selectedProjectId)
            ) || [];

        setSelectedRole(e);
        !!role &&
            onRoleSelect({
                id: role.group.id,
                name: role.group.name,
                productName,
                instanceName,
                projectName,
                roleName
            });
    };

    const dropdownClasses = classnames(
        "dropdown-inherit-width",
        styles["access-selections-form__selection-row-dropdown"]
    );

    return (
        <div className={styles["access-selections-form"]}>
            <div className="flex push--bottom push--top">
                <ProductDropdown
                    disabled={!organizationId || !products}
                    onChange={handleProductChange}
                    products={products}
                    white
                    value={selectedProductId}
                    className={dropdownClasses}
                />
                <InstanceDropdown
                    className={dropdownClasses}
                    disabled={!selectedProductId}
                    instances={selectedProductInstances}
                    onChange={handleInstanceSelection}
                    value={selectedInstanceId}
                    white
                />
            </div>
            {isExp && (
                <div className="push--bottom">
                    <ProjectsDropdown
                        className={dropdownClasses}
                        disabled={!selectedInstanceId}
                        loading={!!selectedInstanceId && (!!isLoadingInitialData || isLoading)}
                        onChange={handleProjectChange}
                        projects={updatedProjectList}
                        value={selectedProjectId}
                        white
                    />
                </div>
            )}
            <RoleSelector
                isDisabled={!selectedProductId || !selectedInstanceId || (isExp && !selectedProjectId)}
                loading={isExp ? !!selectedProjectId && !!isLoadingInitialData : !!selectedInstanceId && isLoading}
                className={dropdownClasses}
                selectedRole={selectedRole}
                fullWidth={false}
                onRoleChanged={handleRoleChange}
                dropdownOptions={roles}
                isInitialized={isExp ? !!selectedProjectId : !!selectedInstanceId && availableGroupRoles !== undefined}
            />
        </div>
    );
};

AccessSelections.displayName = "SupportAccessSelections";
