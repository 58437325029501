import { Role } from "../../../domain/Role";
import { ProductAttribute } from "../../../domain/ProductAttribute";
import { Dropdown, Spinner, Typography } from "@optimizely/axiom";
import React from "react";
import classnames from "classnames";

/*
TODO: To better follow the dropdown pattern - the options should be renamed to `roles` and the value should be the `id` of the role which
should be looked up within the component to display as the `buttonContent`
 */
export const RoleSelector = ({
    className,
    fullWidth = true,
    loading,
    selectedRole,
    project,
    dropdownOptions,
    onRoleChanged,
    placeholder = "Select Role...",
    isDisabled = false,
    hideLabel = false,
    isInitialized = false
}: {
    className?: string;
    dropdownOptions: Role[] | undefined;
    fullWidth?: boolean;
    isDisabled?: boolean;
    loading?: boolean;
    onRoleChanged?: (role: Role) => void;
    placeholder?: string;
    project?: ProductAttribute;
    selectedRole?: Role;
    hideLabel?: boolean;
    isInitialized?: boolean;
}) => {
    const getRoleName = (role: Role) => {
        return role.displayName || role.name;
    };
    const classes = classnames("admin-center__form-dropdown", className);
    let buttonContent: string | React.ReactNode = selectedRole ? getRoleName(selectedRole) : placeholder;
    if (!isInitialized && loading) {
        buttonContent = (
            <>
                <Spinner size="small" /> Loading roles...
            </>
        );
    } else if (isInitialized && !dropdownOptions?.length) {
        buttonContent = "No roles available.";
    }
    return (
        <div className={className}>
            {!hideLabel && (
                <Typography type="body" className="oui-label">
                    Role
                </Typography>
            )}
            <Dropdown
                isDisabled={isDisabled || !dropdownOptions?.length}
                buttonContent={buttonContent}
                arrowIcon="down"
                fullWidth={fullWidth}
                className={classes}
            >
                <Dropdown.Contents>
                    {dropdownOptions?.map((r) => {
                        return (
                            <Dropdown.ListItem key={r.id}>
                                <Dropdown.BlockLink
                                    isItemSelected={r.id === selectedRole?.id}
                                    onClick={() => {
                                        onRoleChanged && onRoleChanged(r);
                                    }}
                                >
                                    {getRoleName(r)}
                                </Dropdown.BlockLink>
                            </Dropdown.ListItem>
                        );
                    })}
                </Dropdown.Contents>
            </Dropdown>
        </div>
    );
};
