import React, { useState } from "react";
import { Attention, Button, Sheet, Table, Typography } from "@optimizely/axiom";
import { UserSearchSelector } from "../UserSearchSelector/UserSearchSelector";
import { User } from "../../../domain/User";
import { datadogRum } from "@datadog/browser-rum";
import { UNEXPECTED_ERROR } from "../../../constants/constants";
import { NotificationUserTableRow } from "../NotificationUserTableRow/NotificationUserTableRow";

type NotificationRecipientsSheetProps = {
    onClose: () => void;
    onSave: ({ recipients }: { recipients: string[] }) => Promise<void>;
    recipientEmails: string[];
    searchHeading?: string;
    subTitle?: string;
    title?: string;
};
export const NotificationRecipientsSheet = ({
    onClose,
    onSave,
    recipientEmails,
    searchHeading,
    subTitle,
    title = "Notification Recipients"
}: NotificationRecipientsSheetProps) => {
    const [recipients, setRecipients] = useState(recipientEmails || []);
    const [error, setError] = useState<string | null>(null);
    const [disabled, setDisabled] = useState(false);
    const [saving, setSaving] = useState(false);

    const addUser = (email: string | undefined) => {
        if (!email) return;
        setRecipients((currentValue) => {
            return [...currentValue, email];
        });
    };

    const userInRecipients = (user: User | undefined) => {
        return !!(user && recipients.includes(user.email));
    };

    const removeAtIndex = (index: number) => {
        setRecipients((currentValue) => {
            return currentValue.filter((_, i) => i !== index);
        });
    };

    const handleSave = () => {
        setDisabled(true);
        setError(null);
        setSaving(true);
        onSave({ recipients })
            .catch((error) => {
                console.error("failed saving: ", error);
                datadogRum.addError(error);
                let errorMessage = UNEXPECTED_ERROR;
                if (Array.isArray(error)) {
                    errorMessage = error[0]?.message || errorMessage;
                } else {
                    errorMessage = error?.message || errorMessage;
                }

                setError(errorMessage);
                setDisabled(false);
            })
            .finally(() => setSaving(false));
    };

    return (
        <Sheet
            subtitle={subTitle}
            footerButtonList={[
                <Button key="cancel" onClick={onClose}>
                    Cancel
                </Button>,
                // eslint-disable-next-line react/style-prop-object
                <Button key="save" isDisabled={disabled} onClick={handleSave} isLoading={saving} style="highlight">
                    Save
                </Button>
            ]}
            onClose={onClose}
            title={title}
        >
            {error && <Attention type="bad-news">{error}</Attention>}
            {searchHeading && (
                <Typography type="body" className="push-double--bottom">
                    {searchHeading}
                </Typography>
            )}
            <UserSearchSelector
                disabled={disabled}
                onSelectUser={(user) => addUser(user?.email)}
                isUserDisabled={userInRecipients}
            />
            <Typography className={`push-triple--top push--bottom`} type="subhead">
                {recipients?.length ? "Selected Users" : "No Users Selected"}
            </Typography>
            <Table
                // eslint-disable-next-line react/style-prop-object
                style="rule"
            >
                <Table.TBody>
                    {recipients?.map((email, recipientIndex) => {
                        return (
                            <>
                                <NotificationUserTableRow
                                    email={email}
                                    onRemove={() => removeAtIndex(recipientIndex)}
                                    key={recipientIndex}
                                />
                            </>
                        );
                    })}
                </Table.TBody>
            </Table>
        </Sheet>
    );
};

NotificationRecipientsSheet.displayName = "NotificationRecipientsSheet";
