import React from "react";
import { BaseFieldProps } from "./BaseFieldProps";
import { handleFieldChange } from "./oidc-form-field-helpers";

export const AuthorizationURLField = ({ disabled = false, onChange, value = "" }: BaseFieldProps) => {
    return (
        <>
            <label className="oui-label push-double--top" htmlFor="authorization-url">
                Authorization URL
            </label>
            <input
                aria-describedby="authorization-url-error"
                className="oui-text-input"
                id="authorization-url"
                type="text"
                disabled={disabled}
                value={value}
                onChange={(e) => handleFieldChange({ e, onChange })}
                placeholder="Authorization URL"
            />
            <span className="oui-form-note" id="authorization-url-description">
                URL of the OAuth 2.0 Authorization Endpoint
            </span>
        </>
    );
};

AuthorizationURLField.displayName = "AuthorizationURLField";
