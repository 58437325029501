import { NavigationBarSettingsSubMenu } from "../ui/components/NavigationBar/NavigationBarSettingsSubMenu";
import { IRoute } from "./navigation-types";

const SECONDARY_NAVIGATION_PATH_MAPPINGS = [{ path: "/settings/", component: NavigationBarSettingsSubMenu }];
export const getSecondaryNavigation = ({ path }: { path: string }): null | (() => JSX.Element | null) => {
    const submenu = SECONDARY_NAVIGATION_PATH_MAPPINGS.find((mapping) =>
        isChildPath({ pathname: path, path: mapping.path })
    )?.component;
    return submenu || null;
};

export const isChildPath = ({ pathname, path }: { pathname: string; path: string }) => {
    return pathname.startsWith(path);
};

export const getTopRoute = ({
    routes,
    pathname,
    productId,
    instanceId,
    projectId
}: {
    routes: IRoute[];
    pathname: string;
    productId?: string;
    instanceId?: string;
    projectId?: string;
}) => {
    return routes.find(
        (r) =>
            r?.path &&
            pathname.startsWith(
                r.path
                    ?.replace("<productId>", productId || "")
                    .replace("<instanceId>", instanceId || "")
                    .replace("<projectId>", projectId || "")
            )
    );
};

export const getCurrentRoute = ({
    routes,
    pathname,
    productId,
    instanceId,
    projectId
}: {
    routes: IRoute[];
    pathname: string;
    productId?: string;
    instanceId?: string;
    projectId?: string;
}): IRoute | undefined => {
    const topRoute = getTopRoute({ routes, pathname, productId, instanceId, projectId });
    let nextRoute: IRoute | undefined;
    if (topRoute && topRoute?.subRoutes) {
        nextRoute = getCurrentRoute({ routes: topRoute.subRoutes || [], pathname, productId, instanceId, projectId });
    }

    return nextRoute || topRoute;
};
