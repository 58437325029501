import { Collection } from "../../domain/Collection";

import { UserGroup } from "../../domain/UserGroup";
import { useServicesContext } from "../../providers/ServicesProvider";
import { usePaginatedList } from "../usePaginatedList/usePaginatedList";
import { DEFAULT_PAGE_SIZE, EVENT_TYPES, SWR_KEY_PREFIX } from "../../constants/constants";
import { useUpdateSync } from "../useUpdateSync/useUpdateSync";
import { difference } from "../../lib/utils";

export const useUserGroups = ({
    organizationId,
    query = "",
    useExactMatchSearch,
    pageSize = DEFAULT_PAGE_SIZE,
    groupType = "",
    groupTypes = [],
    instanceIds = []
}: {
    organizationId?: string;
    query?: string;
    useExactMatchSearch?: boolean;
    pageSize?: number;
    groupType?: string; // todo: deprecate after `hide_product_groups` feature flag is removed
    groupTypes?: string[];
    instanceIds?: string[];
}) => {
    const { userService } = useServicesContext();
    const { fireChangeEvent } = useUpdateSync();

    const { pageData, error, currentPage, setCurrentPage } = usePaginatedList<UserGroup>({
        key: organizationId
            ? `${SWR_KEY_PREFIX.GROUPS}/api/usergroups?${organizationId}&query=${query}&groupType=${
                  groupType || groupTypes
              }&useExactMatchSearch=${useExactMatchSearch}&instanceIds=${instanceIds}`
            : null,
        fetcher: (): Promise<Collection<UserGroup>> => {
            return userService.getUserGroups({
                organizationId: organizationId as string,
                query,
                useExactMatchSearch,
                size: pageSize,
                page: currentPage,
                groupType,
                groupTypes,
                instanceIds
            });
        }
    });

    const addUserGroup = async (newGroup: UserGroup): Promise<UserGroup> => {
        const results = await userService.addUserGroup(newGroup);

        fireChangeEvent({
            type: EVENT_TYPES.ADD_GROUP,
            data: {
                group: { id: results.id, name: results.name, description: results.description }
            }
        });

        return results;
    };

    const updateUserGroup = async ({
        previousUserGroup,
        updatedUserGroup
    }: {
        previousUserGroup: UserGroup;
        updatedUserGroup: UserGroup;
    }): Promise<UserGroup> => {
        const results = await userService.updateUserGroup({
            userGroup: previousUserGroup,
            updatedUserGroup
        });

        const deletedUsers = difference(previousUserGroup.users, updatedUserGroup.users);
        const addedUsers = difference(updatedUserGroup.users, previousUserGroup.users);

        if (deletedUsers.length) {
            fireChangeEvent({
                type: EVENT_TYPES.REMOVE_USER_FROM_GROUP,
                data: {
                    groups: [
                        {
                            id: updatedUserGroup.id
                        }
                    ],
                    users: deletedUsers.map((email) => {
                        return { email };
                    })
                }
            });
        }

        if (addedUsers.length) {
            fireChangeEvent({
                type: EVENT_TYPES.ADD_USER_TO_GROUP,
                data: {
                    groups: [
                        {
                            id: updatedUserGroup.id
                        }
                    ],
                    users: addedUsers.map((email) => {
                        return { email };
                    })
                }
            });
        }

        const deletedInstances = difference(
            previousUserGroup.instancePermissions.map((item) => item.instanceId),
            updatedUserGroup.instancePermissions.map((item) => item.instanceId)
        );
        const addedInstances = difference(
            updatedUserGroup.instancePermissions.map((item) => item.instanceId),
            previousUserGroup.instancePermissions.map((item) => item.instanceId)
        );

        if (deletedInstances.length) {
            fireChangeEvent({
                type: EVENT_TYPES.REMOVE_INSTANCE_FROM_GROUP,
                data: {
                    groups: [{ id: updatedUserGroup.id }],
                    instances: deletedInstances.map((id) => {
                        return {
                            id
                        };
                    })
                }
            });
        }

        if (addedInstances.length) {
            fireChangeEvent({
                type: EVENT_TYPES.ADD_INSTANCE_TO_GROUP,
                data: {
                    groups: [{ id: updatedUserGroup.id }],
                    instances: addedInstances.map((id) => {
                        return { id };
                    })
                }
            });
        }

        if (
            previousUserGroup.name !== updatedUserGroup.name ||
            previousUserGroup.description !== updatedUserGroup.description
        ) {
            fireChangeEvent({
                type: EVENT_TYPES.UPDATE_GROUP,
                data: {
                    group: {
                        id: updatedUserGroup.id,
                        name: updatedUserGroup.name,
                        description: updatedUserGroup.description
                    }
                }
            });
        }

        return results;
    };

    const deleteUserGroup = async ({ userGroupId }: { userGroupId: string }) => {
        const response = await userService.deleteUserGroup({ userGroupId });

        fireChangeEvent({
            type: EVENT_TYPES.DELETE_GROUP,
            data: {
                groups: [{ id: userGroupId }]
            }
        });

        return response;
    };

    const addUserGroupUsers = async ({ userGroupId, userEmails }: { userGroupId: string; userEmails: string[] }) => {
        const response = await userService.addUserGroupUsers({ userGroupId, userEmails });

        fireChangeEvent({
            type: EVENT_TYPES.ADD_USER_TO_GROUP,
            data: {
                users: userEmails.map((email) => {
                    return { email };
                }),
                groups: [{ id: userGroupId }]
            }
        });

        return response;
    };

    const deleteUserGroupUsers = async ({ userGroupId, userEmails }: { userGroupId: string; userEmails: string[] }) => {
        const response = await userService.deleteUserGroupUsers({ userGroupId, userEmails });

        fireChangeEvent({
            type: EVENT_TYPES.REMOVE_USER_FROM_GROUP,
            data: {
                users: userEmails.map((email) => {
                    return { email, userGroupIds: [userGroupId] };
                }),
                groups: [{ id: userGroupId }]
            }
        });

        return response;
    };

    return {
        userGroups: pageData?.items,
        isLoading: !error && !pageData,
        isSearching: !error && !pageData && query.length > 2,
        error,
        currentPage,
        setCurrentPage,
        addUserGroup,
        deleteUserGroup,
        updateUserGroup,
        pageSize: pageSize,
        totalCount: pageData?.totalCount || 0,
        addUserGroupUsers,
        deleteUserGroupUsers
    };
};
