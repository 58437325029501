/* eslint-disable react/style-prop-object */
import React, { useEffect, useState } from "react";
import { Attention, Button, Checkbox, Icon, Poptip } from "@optimizely/axiom";
import { useForm } from "react-hook-form";

import { Invitation } from "../../../domain/Invitation";
import {
    INVITATION_ERROR_MESSAGE,
    INVITATION_SUCCESS_MESSAGE,
    REFETCH_PERMISSIONS_EVENT_NAME
} from "../../../constants/constants";
import { adaptApiErrors, IApiError } from "../../../services/ErrorMessageAdapter";
import styles from "../InvitationDetails/InvitationDetails.module.scss";
import { SidebarFooter } from "../Sidebar/SidebarFooter";
import { useUserContext } from "../../../providers/UserProvider";
import { useLocation } from "react-router-dom";
import { isValidEmail } from "../../../lib/utils";
import { UserSearchSelector } from "../UserSearchSelector/UserSearchSelector";
import { User } from "../../../domain/User";
import { emitToast } from "../../../lib/toaster-utils";
import { InvitationAccessForm, UserGroupWithProjectDetails } from "./InvitationAccess/InvitationAccessForm";
import { getGrantPermissionsPayload } from "../../../lib/analytics-helpers";
import { useAnalyticsTracking } from "../../../hooks/useAnalyticsTracking/useAnalyticsTracking";
import { ANALYTICS_FLOWS, ANALYTICS_TRACKED_COMPONENTS } from "../../../constants/analytics-constants";
import { useOrganization } from "../../../hooks/useOrganization/useOrganization";

interface IInvitationFormValues {
    firstName: string;
    lastName: string;
    apiError: { message: string };
}

export const AccessManagementInvitationForm = ({
    onCancel,
    onCreate,
    usersFlow = false
}: {
    onCancel: () => void;
    onCreate: ({
        newInvitation,
        inviteAnother
    }: {
        newInvitation: Invitation | null;
        inviteAnother: boolean;
    }) => Promise<boolean>;
    usersFlow?: boolean;
}) => {
    const { organizationId, profile } = useUserContext();
    const [selectedRoleGroups, setSelectedRoleGroups] = useState<UserGroupWithProjectDetails[]>([]);
    const [selectedUser, setSelectedUser] = useState<User>();
    const [email, setEmail] = useState<string | null>(null);
    const { pathname } = useLocation();
    const { sendTrackEvent } = useAnalyticsTracking();

    const { organization, loading: organizationLoading } = useOrganization({ id: organizationId });
    const showReqAcceptanceChkBox = !organizationLoading && !organization?.ssoEnabled && usersFlow;
    const [requireAcceptance, setRequireAcceptance] = useState<boolean>(true);

    const {
        register,
        handleSubmit,
        getValues,
        setValue,
        setError,
        reset,
        watch,
        formState: { errors, isValid }
    } = useForm<IInvitationFormValues>({ mode: "onChange" });

    watch("firstName");
    watch("lastName");

    const [saving, setSaving] = useState(false);
    const [inviteAnother, setInviteAnother] = useState(false);
    const isProductAccessFlow = pathname.includes("/access/");

    const createNewInvitation = () => {
        const { firstName, lastName } = getValues();

        if (selectedRoleGroups?.length && email) {
            return new Invitation({
                firstName,
                lastName,
                email: email,
                requireAcceptance: showReqAcceptanceChkBox ? requireAcceptance : false,
                organizationId: organizationId!,
                userGroups: selectedRoleGroups?.map((g) => ({ id: g.id!, name: g.name! })),
                createdBy: profile?.email!,
                created: new Date(),
                modified: new Date()
            });
        }

        return null;
    };

    const handleError = (apiErrors: IApiError[] | Error) => {
        if (Array.isArray(apiErrors)) {
            const errors = adaptApiErrors(apiErrors);
            errors.forEach((error) => {
                setError(error.name as any, {
                    type: "individualFieldApiError",
                    message: error.message
                });
            });
        } else {
            setError("apiError", {
                type: "apiError",
                message: INVITATION_ERROR_MESSAGE
            });
        }
    };

    const handleFormSubmission = async () => {
        const newInvitation = createNewInvitation();
        setSaving(true);
        onCreate({ newInvitation, inviteAnother })
            .then(async () => {
                const [userGroupIds, userGroupNames, projectIds, projectNames] = selectedRoleGroups?.reduce(
                    (acc: [string[], string[], string[], string[]], group: Partial<UserGroupWithProjectDetails>) => {
                        acc[0].push(group.id!);
                        acc[1].push(group.name!);
                        if (group.projectName) {
                            acc[2].push(group.projectId!);
                            acc[3].push(group.projectName!);
                        } else if (group.projectIds?.length && group.projectNames?.length) {
                            acc[2].push(...group.projectIds);
                            acc[3].push(...group.projectNames);
                        }

                        return acc;
                    },
                    [[], [], [], []]
                );
                const payload = getGrantPermissionsPayload({
                    component: ANALYTICS_TRACKED_COMPONENTS.INVITATION_SIDEBAR,
                    userGroupIds,
                    userGroupNames,
                    email: newInvitation?.email!,
                    projectIds,
                    projectNames,
                    flow: isProductAccessFlow ? ANALYTICS_FLOWS.PRODUCT_ACCESS : ANALYTICS_FLOWS.USER_PAGE
                });
                sendTrackEvent(payload);

                // reset form values if invite another is checked
                if (inviteAnother) {
                    setEmail(null);
                    reset();
                }
                emitToast({ message: INVITATION_SUCCESS_MESSAGE });
                window.dispatchEvent(new CustomEvent(REFETCH_PERMISSIONS_EVENT_NAME));
            })
            .catch(handleError)
            .finally(() => {
                setSaving(false);
            });
    };
    useEffect(() => {
        if (email !== selectedUser?.email) {
            setValue("firstName", "", { shouldValidate: !!selectedUser });
            setValue("lastName", "", { shouldValidate: !!selectedUser });
            setSelectedUser(undefined);
        }
    }, [email, selectedUser, setValue]);

    const emailError = email === null || isValidEmail(email) ? "" : "Please enter a valid email";
    const isSaveDisabled = !selectedRoleGroups?.length || !isValid || !email || !isValidEmail(email);

    return (
        <form className="flex flex--column user-form" onSubmit={handleSubmit(handleFormSubmission)}>
            {errors.apiError && (
                <Attention alignment="left" className="push--top push-quad--bottom push-quad--sides" type="bad-news">
                    {errors.apiError.message}
                </Attention>
            )}
            <div className={styles["invitation-details__content"]}>
                <div className={`push-quad--bottom soft-quad--sides ${emailError && "oui-form-bad-news"}`}>
                    <label className="oui-label" htmlFor="invitation-email">
                        Email
                        <span aria-label="(required)" className="oui-label--required" />
                        <Poptip
                            className="push--left"
                            content="Enter the user's email. Existing users will appear for selection, with their details auto-filled. For new users, enter their info manually."
                            isInline
                        >
                            <Icon name="circle-info" size="small" />
                        </Poptip>
                    </label>

                    <UserSearchSelector
                        placeholder="Enter the user's email..."
                        onSelectUser={async (user) => {
                            if (user?.email) {
                                setSelectedUser(user);

                                setValue("firstName", user.firstName, { shouldValidate: true });
                                setValue("lastName", user.lastName, { shouldValidate: true });
                                setEmail(user.email);
                                reset(undefined, { keepValues: true });
                            }
                        }}
                        onChange={async (email) => {
                            setEmail(email);
                            reset(undefined, { keepValues: true });
                        }}
                        clearOnSelect={false}
                    />

                    {emailError && (
                        <span className="oui-form-note" id="invitation-email-error">
                            {emailError}
                        </span>
                    )}
                </div>
                <div className={`push-double--bottom soft-quad--sides ${errors.firstName && "oui-form-bad-news"}`}>
                    <label className="oui-label" htmlFor="invitation-first-name">
                        First Name
                        <span aria-label="(required)" className="oui-label--required" />
                    </label>

                    <input
                        aria-describedby="invitation-first-name-error"
                        className="oui-text-input"
                        id="invitation-first-name"
                        type="text"
                        disabled={!!selectedUser || !email || !!emailError}
                        {...register("firstName", { required: true })}
                    />
                    {errors.firstName && (
                        <span className="oui-form-note" id="invitation-first-name-error">
                            First name is required
                        </span>
                    )}
                </div>

                <div className={`push-quad--bottom soft-quad--sides ${errors.lastName && "oui-form-bad-news"}`}>
                    <label className="oui-label" htmlFor="invitation-last-name">
                        Last Name
                        <span aria-label="(required)" className="oui-label--required" />
                    </label>

                    <input
                        aria-describedby="invitation-last-name-error"
                        className="oui-text-input"
                        id="invitation-last-name"
                        type="text"
                        disabled={!!selectedUser || !email || !!emailError}
                        {...register("lastName", { required: true })}
                    />
                    {errors.lastName && (
                        <span className="oui-form-note" id="invitation-last-name-error">
                            Last name is required
                        </span>
                    )}
                </div>

                {showReqAcceptanceChkBox && (
                    <div className="push-quad--bottom soft-quad--sides">
                        <Checkbox
                            checked={requireAcceptance}
                            label="Require Acceptance"
                            description="The user must accept the invitation sent to their inbox"
                            onChange={() => setRequireAcceptance(!requireAcceptance)}
                        />
                    </div>
                )}
                <InvitationAccessForm
                    className="push-quad--bottom soft-quad--sides"
                    email={isValidEmail(email || "") ? email : ""}
                    organizationId={organizationId}
                    setSelectedRoleGroups={setSelectedRoleGroups}
                />
            </div>
            <div className="flex">
                <SidebarFooter
                    onCancel={onCancel}
                    leftSide={[
                        <Checkbox
                            key="invite-another"
                            label="Invite another"
                            checked={inviteAnother}
                            onChange={(event) => {
                                setInviteAnother(event.target.checked);
                            }}
                        />
                    ]}
                >
                    <Button
                        key="send-invitation-button"
                        className=""
                        isLoading={saving}
                        loadingText="Saving"
                        style="highlight"
                        isSubmit
                        isDisabled={isSaveDisabled}
                    >
                        Send
                    </Button>
                </SidebarFooter>
            </div>
        </form>
    );
};

AccessManagementInvitationForm.displayName = "AccessManagementInvitationForm";
