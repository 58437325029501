/* eslint-disable react/style-prop-object */
import { useUserContext } from "../../../providers/UserProvider";
import { LoadingIndicator } from "../LoadingIndicator/LoadingIndicator";

import { Unauthorized } from "../../pages/Unauthorized/Unauthorized";
import { useContextUsers } from "../../../hooks/useContextUsers/useContextUsers";

import UserTable from "../UserList/UserTable";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import styles from "../../pages/UserList/UserList.module.scss";
import { SearchInput } from "../SearchInput/SearchInput";
import { useDebounce } from "../../../hooks/useDebounce/useDebounce";
import { CONTEXT_SCOPES, DEFAULT_DEBOUNCE, DEFAULT_PAGE_SIZE } from "../../../constants/constants";
import { ResetFilterPrompt } from "../ResetFilterPrompt/ResetFilterPrompt";
import { Sidebar } from "../Sidebar/Sidebar";
import { useFormContext } from "../UserForm/UserFormContext/UserFormContext";
import { User } from "../../../domain/User";
import { ConfirmationDialog } from "../ConfirmationDialog/ConfirmationDialog";
import { datadogRum } from "@datadog/browser-rum";
import { InviteUserButtonAndSidebar } from "../InviteUserButtonAndSidebar/InviteUserButtonAndSidebar";
import { AccessManagementUserForm } from "./AccessManagementUserForm/AccessManagementUserForm";
import { useAccessFlowUserFormContext } from "./AccessManagementUserForm/AccessManagementUserFormProvider";
import { UnsavedChangesDialog } from "../UnsavedChangesDialog/UnsavedChangesDialog";
import { Dropdown, PaginationControls, Typography } from "@optimizely/axiom";
import { useContextRoles } from "../../../hooks/useContextRoles/useContextRoles";
import { useContextProjects } from "../../../hooks/useContextProjects/useContextProjects";
import { useProducts } from "../../../hooks/useProducts/useProducts";
import { ActivationStatusesModal } from "../ActivationStatusesTable/ActivationStatusesModal";
import { emitToast } from "../../../lib/toaster-utils";
import { useAnalyticsTracking } from "../../../hooks/useAnalyticsTracking/useAnalyticsTracking";
import { getRemoveUserPayload } from "../../../lib/analytics-helpers";
import { ANALYTICS_FLOWS } from "../../../constants/analytics-constants";
import { Role } from "../../../domain/Role";

export const AccessManagementUsers = ({ disableDeleteFromOptiId = false }: { disableDeleteFromOptiId?: boolean }) => {
    const { pathname } = useLocation();
    const { productId, instanceId, projectId } = useParams();
    const { organizationId = "", accessContext, setAccessContext } = useUserContext();

    const { products, isLoading: areProductsLoading, error: productsError } = useProducts({ organizationId });
    const instance = products?.flatMap((p) => p.instances).find((inst) => inst.id === instanceId);

    const { activeField, setActiveField } = useAccessFlowUserFormContext();
    const [confirmationError, setConfirmationError] = useState<string>("");
    const [userToRemoveAccess, setUserToRemoveAccess] = useState<User | undefined>();
    const [userToDelete, setUserToDelete] = useState<User | undefined>();
    const [inProgress, setInProgress] = useState(false);

    const { accessManagementUserState, updateAccessManagementUserState } = useFormContext();
    const { user: selectedUser } = accessManagementUserState;

    const [selectedRoleFilter, setSelectedRoleFilter] = useState<string | undefined>();
    const [selectedProjectFilter, setSelectedProjectFilter] = useState<string | undefined>();
    const [showActivationStatusesModal, setShowActivationStatusesModal] = useState<boolean>(false);
    const { sendTrackEvent } = useAnalyticsTracking();

    const {
        debouncedValue: debouncedSearchQuery,
        value: searchQuery,
        setValue: setSearchQuery
    } = useDebounce<string>("", DEFAULT_DEBOUNCE);

    const { users, isLoading, error, currentPage, setCurrentPage, totalCount, removeUserAccess, deleteUser } =
        useContextUsers({
            organizationId,
            context: accessContext,
            roleIdFilter: selectedRoleFilter,
            projectIdFilter: selectedProjectFilter,
            includeExternalStatus: true,
            searchQuery: debouncedSearchQuery.length > 2 ? debouncedSearchQuery : ""
        });

    const { roles } = useContextRoles({ organizationId, context: accessContext });
    const { projects } = useContextProjects({ context: accessContext });

    const isExp = productId === process.env.REACT_APP_EXPERIMENTATION_PRODUCT_ID;

    const roleFilterSelected = (value?: string) => {
        setSelectedRoleFilter(value);
    };

    const projectFilterSelected = (value?: string) => {
        setSelectedProjectFilter(value);
    };

    const closeSideNav = useCallback(() => {
        updateAccessManagementUserState({ user: null, editing: false });
    }, [updateAccessManagementUserState]);

    const hasDefaultUserFilters = debouncedSearchQuery.length < 2 && !selectedRoleFilter && !selectedProjectFilter;
    const noDefaultFilterUsers = !isLoading && hasDefaultUserFilters && !users?.length;

    useEffect(() => {
        window.document.addEventListener("confirmation-dialog:name:discard-changes", closeSideNav);
        return () => {
            window.document.removeEventListener("confirmation-dialog:name:discard-changes", closeSideNav);
        };
    }, [closeSideNav]);

    useEffect(() => {
        return () => setConfirmationError("");
    }, [userToDelete, userToRemoveAccess]);

    useEffect(() => {
        return closeSideNav;
    }, [currentPage, closeSideNav]);

    const orgUsersBaseRoutes =
        pathname === "/access/users" ||
        pathname === "/access/users/" ||
        pathname === "/user-management/users" ||
        pathname === "/user-management/users/";

    useEffect(() => {
        if (orgUsersBaseRoutes) {
            setAccessContext({ scope: CONTEXT_SCOPES.ORGANIZATION, items: [{ id: organizationId }] });
        } else if (productId && !instanceId && !projectId) {
            setAccessContext({ scope: CONTEXT_SCOPES.PRODUCT, items: [{ id: productId }] });
        } else if (productId && instanceId && !projectId) {
            setAccessContext({ scope: CONTEXT_SCOPES.INSTANCE, items: [{ id: instanceId }] });
        } else if (projectId) {
            setAccessContext({ scope: CONTEXT_SCOPES.PROJECT, items: [{ id: projectId }] });
        } else {
            setAccessContext(undefined);
        }
    }, [orgUsersBaseRoutes, setAccessContext, productId, instanceId, projectId, organizationId]);

    const handleDeletion = () => {
        if (userToDelete) {
            setInProgress(true);
            deleteUser({ user: userToDelete })
                .then(() => {
                    const payload = getRemoveUserPayload({
                        email: userToDelete.email,
                        flow: ANALYTICS_FLOWS.USER_PAGE
                    });
                    sendTrackEvent(payload);
                    setUserToDelete(undefined);
                    emitToast({ message: "User successfully deleted." });
                })
                .catch((error) => {
                    datadogRum.addError(error);
                    setConfirmationError("An error occurred while deleting user");
                    console.error(error);
                })
                .finally(() => {
                    setInProgress(false);
                });
        }
    };

    const handleRemoveAccess = () => {
        if (userToRemoveAccess && accessContext) {
            setInProgress(true);

            removeUserAccess({ user: userToRemoveAccess })
                .then(() => {
                    setUserToRemoveAccess(undefined);
                    emitToast({ message: "Access removed successfully." });
                })
                .catch((error) => {
                    datadogRum.addError(error);
                    setConfirmationError("An error occurred while removing access for user");
                    console.error(error);
                })
                .finally(() => {
                    setInProgress(false);
                });
        }
    };

    const handleFormCancel = () => {
        setActiveField({ name: null, hasChanged: false });
        if (!activeField?.hasChanged) {
            closeSideNav();
        }
    };

    const goToPage = (page = 1) => {
        setCurrentPage(page);
    };

    const totalPages = Math.ceil(totalCount / DEFAULT_PAGE_SIZE);
    const showPagination = totalPages > 1;

    if (!accessContext) return <Unauthorized />;

    if (
        !areProductsLoading &&
        !productsError &&
        [CONTEXT_SCOPES.INSTANCE, CONTEXT_SCOPES.PROJECT].indexOf(accessContext.scope) > -1 &&
        !instance?.optiIdEnabled
    )
        return (
            <div className="push-double--top">
                {accessContext.scope === CONTEXT_SCOPES.PROJECT
                    ? "This project's instance must be Opti Id Enabled in order to add users."
                    : "This instance must be Opti ID Enabled in order to add users."}
            </div>
        );

    if (error) return <div className="push-double--top">Error fetching users list.</div>;

    const getRoleName = (role?: Role) => {
        if (!role) return "Unknown";

        const { displayName, isInstanceRole, name = "" } = role;

        if (accessContext?.scope === CONTEXT_SCOPES.PROJECT) {
            return isInstanceRole ? `${displayName} (All Projects)` : displayName;
        }

        return name;
    };

    const removeAccessCTA = disableDeleteFromOptiId ? "Delete" : "Remove Access";
    const removeTitle = disableDeleteFromOptiId ? "Delete User" : "Remove Access for User";

    return (
        <>
            {!!userToDelete && (
                <ConfirmationDialog
                    callToActionText="Delete"
                    error={confirmationError}
                    loading={inProgress}
                    onCancel={() => setUserToDelete(undefined)}
                    onConfirmation={handleDeletion}
                    title="Delete User"
                >
                    Are you sure you want to delete this user?
                </ConfirmationDialog>
            )}

            {!!userToRemoveAccess && (
                <ConfirmationDialog
                    callToActionText={removeAccessCTA}
                    error={confirmationError}
                    loading={inProgress}
                    onCancel={() => setUserToRemoveAccess(undefined)}
                    onConfirmation={handleRemoveAccess}
                    title={removeTitle}
                >
                    <Typography type="body">
                        {disableDeleteFromOptiId ? (
                            <>
                                <p>Are you sure you want to permanently remove this user from your organization?</p>
                                This action cannot be undone and may impact associated accounts and data.
                            </>
                        ) : (
                            <>Are you sure you want to remove access to the {accessContext.scope} for this user?</>
                        )}
                    </Typography>
                </ConfirmationDialog>
            )}
            <div className={`list-page ${styles["user-list"]}`}>
                <div className={styles["user-list__bar"]}>
                    <div className={styles["user-list__leftgroup"]}>
                        <div className={styles["user-list__search"]}>
                            <SearchInput
                                placeholder="Search users by name or email..."
                                type="text"
                                value={searchQuery}
                                onChange={({ value }) => {
                                    setSearchQuery(value);
                                }}
                                disabled={noDefaultFilterUsers}
                            />
                        </div>
                        <div className={styles["user-list__filter"]}>
                            {(accessContext?.scope === CONTEXT_SCOPES.INSTANCE ||
                                accessContext?.scope === CONTEXT_SCOPES.PROJECT) && (
                                <Dropdown
                                    className="push--left"
                                    buttonContent={{
                                        label: "Role",
                                        content: selectedRoleFilter
                                            ? getRoleName(roles.find((r) => r.id === selectedRoleFilter))
                                            : "All"
                                    }}
                                    style="plain"
                                    arrowIcon="down"
                                    width={350}
                                    isDisabled={noDefaultFilterUsers}
                                >
                                    <Dropdown.Contents>
                                        <Dropdown.ListItem role="option" key="All">
                                            <Dropdown.BlockLink isLink onClick={() => roleFilterSelected(undefined)}>
                                                <Dropdown.BlockLinkText text="All" />
                                            </Dropdown.BlockLink>
                                        </Dropdown.ListItem>
                                        {roles
                                            .sort((r1, r2) => {
                                                if (r1?.displayName > r2?.displayName) {
                                                    return 1;
                                                }

                                                if (r1?.displayName < r2?.displayName) {
                                                    return -1;
                                                }

                                                return 0;
                                            })
                                            .map((role) => {
                                                return (
                                                    <Dropdown.ListItem role="option" key={role.id}>
                                                        <Dropdown.BlockLink
                                                            isLink
                                                            onClick={() => roleFilterSelected(role.id)}
                                                        >
                                                            <Dropdown.BlockLinkText text={getRoleName(role)} />
                                                        </Dropdown.BlockLink>
                                                    </Dropdown.ListItem>
                                                );
                                            })}
                                    </Dropdown.Contents>
                                </Dropdown>
                            )}
                            {accessContext?.scope === CONTEXT_SCOPES.INSTANCE && isExp && (
                                <Dropdown
                                    className="push--left"
                                    buttonContent={{
                                        label: "Project",
                                        content: selectedProjectFilter
                                            ? projects?.find((p) => p.id === selectedProjectFilter)?.name
                                            : "All"
                                    }}
                                    style="plain"
                                    arrowIcon="down"
                                    width={350}
                                    isDisabled={noDefaultFilterUsers}
                                >
                                    <Dropdown.Contents>
                                        <Dropdown.ListItem role="option" key="All">
                                            <Dropdown.BlockLink isLink onClick={() => projectFilterSelected(undefined)}>
                                                <Dropdown.BlockLinkText text="All" />
                                            </Dropdown.BlockLink>
                                        </Dropdown.ListItem>
                                        {projects?.map((project) => (
                                            <Dropdown.ListItem role="option" key={project.id}>
                                                <Dropdown.BlockLink
                                                    isLink
                                                    onClick={() => projectFilterSelected(project.id)}
                                                >
                                                    <Dropdown.BlockLinkText text={project.name} />
                                                </Dropdown.BlockLink>
                                            </Dropdown.ListItem>
                                        ))}
                                    </Dropdown.Contents>
                                </Dropdown>
                            )}
                        </div>
                    </div>

                    <InviteUserButtonAndSidebar />
                </div>

                {showActivationStatusesModal && <ActivationStatusesModal showModal={setShowActivationStatusesModal} />}

                {(debouncedSearchQuery.length > 2 || selectedRoleFilter || selectedProjectFilter) &&
                    !users?.length &&
                    !isLoading && (
                        <ResetFilterPrompt
                            onClick={() => {
                                setSearchQuery("");
                                setSelectedRoleFilter(undefined);
                                setSelectedProjectFilter(undefined);
                            }}
                            prompt="No users match your filter."
                        />
                    )}
                {noDefaultFilterUsers && <div>No users have access at this level</div>}
                <UnsavedChangesDialog />
                {!!selectedUser && (
                    <Sidebar heading={"User"} isOpen onCancel={handleFormCancel} noTransform>
                        <AccessManagementUserForm
                            onCancel={handleFormCancel}
                            organizationId={organizationId}
                            showModal={setShowActivationStatusesModal}
                            user={selectedUser}
                        />
                    </Sidebar>
                )}

                {isLoading ? (
                    <LoadingIndicator height="100%" type="spinner" />
                ) : (
                    <>
                        <UserTable
                            userList={users}
                            disableDeleteFromOptiId={disableDeleteFromOptiId}
                            onViewDetails={(user) => updateAccessManagementUserState({ user, editing: false })}
                            onDeleteUser={(user) => setUserToDelete(user)}
                            onRemoveAccess={(user) => setUserToRemoveAccess(user)}
                        />

                        {showPagination && (
                            <PaginationControls
                                className="anchor--bottom"
                                currentPage={currentPage}
                                goToPage={goToPage}
                                totalPages={totalPages}
                            />
                        )}
                    </>
                )}
            </div>
        </>
    );
};

AccessManagementUsers.displayName = "AccessManagementUsers";
