import useSWR from "swr";
import { useServicesContext } from "../../providers/ServicesProvider";
import {
    IOrganizationSettings,
    OrganizationSettings as RestOrganizationSettings
} from "../../rest-model/OrganizationSettings";

export const useOrganizationSettings = ({ organizationId }: { organizationId?: string }) => {
    const { organizationSettingsService } = useServicesContext();

    const {
        data: organizationSettings,
        error,
        mutate
    } = useSWR<IOrganizationSettings, Error>(
        organizationId ? `/api/organizations/${organizationId}/settings` : null,
        () => {
            if (!organizationId) return {} as IOrganizationSettings;
            return organizationSettingsService!.getOrganizationSettings({ organizationId });
        }
    );

    const updateOrganizationSettings = async ({ updates }: { updates: Partial<RestOrganizationSettings> }) => {
        if (!organizationId) return;
        const result = await organizationSettingsService?.updateOrganizationSettings({ organizationId, updates });
        if (result) mutate(result);
    };

    const updateGenAISettings = async ({ enabled }: { enabled: boolean }) => {
        if (!organizationId) return;
        const result = await organizationSettingsService?.updateGenAISettings({
            organizationId,
            settings: { enabled }
        });
        if (result) mutate({ ...organizationSettings, genAI: { enabled } });
    };

    return {
        settings: organizationSettings,
        loading: !error && !organizationSettings,
        updateGenAISettings,
        updateOrganizationSettings,
        error
    };
};
