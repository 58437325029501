import { SetStateAction, useState } from "react";
import { Flags } from "../../../../feature-flags/flags";
import { useFeatureFlag } from "../../../../hooks/useFeatureFlag/useFeatureFlag";
import {
    ACCOUNT_ACTIVATION_UNKNOWN_ERROR_MESSAGE,
    ACTIVATE_USER_SUCCESS_MESSAGE,
    UNLOCK_USER_SUCCESS_MESSAGE,
    USER_EXTERNAL_STATUS
} from "../../../../constants/constants";
import { Button, Typography } from "@optimizely/axiom";
import styles from "./UserFormActivationStatus.module.scss";
import { datadogRum } from "@datadog/browser-rum";
import { AccessManagementUserFormIconButton } from "./AccessManagementUserFormIconButton";
import { emitToast } from "../../../../lib/toaster-utils";
import { useUser } from "../../../../hooks/useUser/useUser";

export const UserFormActivationStatus = ({
    user,
    setError,
    showModal
}: {
    user: { email: string };
    setError: React.Dispatch<SetStateAction<string | null>>;
    showModal: React.Dispatch<SetStateAction<boolean>>;
}) => {
    const { email } = user;

    const [loadingUnlock, setLoadingUnlock] = useState(false);
    const [loadingActivate, setLoadingActivate] = useState(false);

    const { enabled: showUserActivationStatus } = useFeatureFlag(Flags.SHOW_USER_ACTIVATION_STATUS);
    const { user: updatedUser, activateUser, unlockUser, revalidate } = useUser({ email, includeExternalStatus: true });

    const showActivateUserAction =
        updatedUser?.externalStatus === "STAGED" || updatedUser?.externalStatus === "PROVISIONED";
    const showUnlockUserAction = updatedUser?.externalStatus === "LOCKEDOUT";

    const handleUnlockUser = async () => {
        setLoadingUnlock(true);
        unlockUser({ email })
            .then(async () => {
                revalidate()
                    .catch((e) => {
                        setLoadingUnlock(false);
                        setError(`${e.message}`);
                        datadogRum.addError(e);
                        console.error(e);
                    })
                    .finally(() => {
                        setLoadingUnlock(false);
                    });
                emitToast({ message: UNLOCK_USER_SUCCESS_MESSAGE });
            })
            .catch((e) => {
                setLoadingUnlock(false);
                setError(`${ACCOUNT_ACTIVATION_UNKNOWN_ERROR_MESSAGE}: ${e.message}`);
                datadogRum.addError(e);
                console.error(ACCOUNT_ACTIVATION_UNKNOWN_ERROR_MESSAGE, e);
            })
            .finally(() => {
                setLoadingUnlock(false);
            });
    };

    const handleActivateUser = async () => {
        setLoadingActivate(true);
        activateUser({ email })
            .then(async () => {
                // not technically activated, only email is sent; user action needed
                emitToast({ message: ACTIVATE_USER_SUCCESS_MESSAGE });
            })
            .catch((e) => {
                setLoadingActivate(false);
                setError(`${ACCOUNT_ACTIVATION_UNKNOWN_ERROR_MESSAGE}: ${e.message}`);
                datadogRum.addError(e);
                console.error(ACCOUNT_ACTIVATION_UNKNOWN_ERROR_MESSAGE, e);
            })
            .finally(() => {
                setLoadingActivate(false);
            });
    };

    return (
        <>
            {showUserActivationStatus && (
                <div className="flex--1">
                    <div className={styles["status-label"]}>
                        <label className={`${styles["activation-status-label"]} oui-label`}>Activation Status</label>
                        <span className={`${styles["activation-status-icon"]}`}>
                            <AccessManagementUserFormIconButton
                                className="activation-status-icon"
                                icon="circle-info"
                                onClick={() => showModal(true)}
                            />
                        </span>
                    </div>
                    <Typography type="body">
                        {updatedUser?.externalStatus && USER_EXTERNAL_STATUS[updatedUser?.externalStatus]}
                        {showActivateUserAction && (
                            <Button
                                isLoading={loadingActivate}
                                className={`link button-as-link push--left ${styles["activation-status-buttons"]}`}
                                onClick={handleActivateUser}
                            >
                                {" Resend Activation Email "}
                            </Button>
                        )}
                        {showUnlockUserAction && (
                            <Button
                                isLoading={loadingUnlock}
                                className={`link button-as-link push--left ${styles["activation-status-buttons"]}`}
                                onClick={handleUnlockUser}
                            >
                                {" Unlock User "}
                            </Button>
                        )}
                    </Typography>
                </div>
            )}
        </>
    );
};

UserFormActivationStatus.displayName = "UserFormActivationStatus";
