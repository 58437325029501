import React, { useMemo } from "react";
import { Spinner, Typography } from "@optimizely/axiom";
import { DuplicateRole } from "./DuplicateRole";
import { Role } from "../../../domain/Role";
import { RoleAttribute } from "../../../domain/RoleAttribute.interface";
import { SelectRoleProjects } from "./SelectRoleProjects";
import { SelectRoleAttributes } from "./SelectRoleAttributes";
import { TeamAssociationCheckbox } from "./TeamAssociationCheckbox";
import { useAttributes } from "../../../hooks/useAttributes/useAttributes";
import { ProductAttribute } from "../../../domain/ProductAttribute";
import { useFeatureFlag } from "../../../hooks/useFeatureFlag/useFeatureFlag";
import { Flags } from "../../../feature-flags/flags";

type RoleAttributeSelectionsProps = {
    hideDuplicateRole?: boolean;
    onChange: ({
        preMappedValues,
        id,
        key,
        values
    }: {
        id?: string;
        key?: string;
        preMappedValues?: boolean;
        values: string[] | RoleAttribute[];
    }) => void;
    productId?: string;
    instanceId?: string;
    roles: Role[];
    selectedAttributes?: RoleAttribute[];
    rolePermissionValue?: string;
};
export const RoleAttributeSelections = ({
    hideDuplicateRole,
    onChange,
    productId,
    instanceId,
    roles,
    selectedAttributes = [],
    rolePermissionValue = ""
}: RoleAttributeSelectionsProps) => {
    const isExp = productId === process.env.REACT_APP_EXPERIMENTATION_PRODUCT_ID;
    const { enabled: enableCmpRoleEnhancement } = useFeatureFlag(Flags.ENABLE_CMP_ROLE_ENHANCEMENT);

    const { attributes, isLoadingInitialData: isLoading } = useAttributes({
        isActive: true,
        productId
    });

    const [cmpTeamAttribute, nonCMPTeamAttributes] = useMemo(() => {
        let cmpAttr: ProductAttribute | null = null;
        const nonCMPAttrs: ProductAttribute[] = [];
        attributes?.forEach((attr) => {
            if (attr.productId === process.env.REACT_APP_CMP_PRODUCT_ID && attr.key === "Team") {
                cmpAttr = attr;
            } else {
                nonCMPAttrs.push(attr);
            }
        });
        return [cmpAttr, nonCMPAttrs] as [ProductAttribute | null, ProductAttribute[]];
    }, [attributes]);

    const cmpTeamAttributeEnabled = selectedAttributes.some((attr) => attr.id === cmpTeamAttribute?.id);

    const productRoles = roles.filter((r) => r.productId === productId);

    const handleDuplicateSelectionChange = ({ role }: { role?: Role }) => {
        onChange({ preMappedValues: true, values: role ? role.attributes : [] });
    };

    if (isLoading) {
        return (
            <div className="push-triple--top flex justify-content-center">
                <Spinner />
            </div>
        );
    }

    return (
        <>
            {!hideDuplicateRole && !!roles.length && (
                <div className="push-triple--top">
                    <DuplicateRole roles={productRoles} onChange={handleDuplicateSelectionChange} />
                </div>
            )}
            {enableCmpRoleEnhancement && !!cmpTeamAttribute && (
                <TeamAssociationCheckbox
                    cmpTeamAttribute={cmpTeamAttribute}
                    cmpTeamAttributeEnabled={cmpTeamAttributeEnabled}
                    onChange={onChange}
                />
            )}
            <div className="push-triple--top">
                <Typography type="header4">
                    <span style={{ fontWeight: 300 }}>
                        Select Attributes <span aria-label="(required)" className="oui-label--required" />
                    </span>
                </Typography>
            </div>
            <div className="push--bottom">
                <Typography type="body" className="push--bottom">
                    Check all attributes to be assigned to this role.
                </Typography>
            </div>

            {isExp ? (
                <SelectRoleProjects
                    onChange={onChange}
                    productId={productId}
                    instanceId={instanceId}
                    selectedAttributes={selectedAttributes}
                    rolePermissionValue={rolePermissionValue}
                />
            ) : (
                <SelectRoleAttributes
                    attributes={nonCMPTeamAttributes}
                    onChange={onChange}
                    productId={productId}
                    selectedAttributes={selectedAttributes}
                />
            )}
        </>
    );
};

RoleAttributeSelections.displayName = "RoleAttributeSelections";
