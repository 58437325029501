import React, { createContext, useContext, useReducer } from "react";

import { User } from "../../../../domain/User";
import { UserGroup } from "../../../../domain/UserGroup";
import { Invitation } from "../../../../domain/Invitation";

type trackedAccessManagementUser = {
    editing?: boolean;
    user?: User;
};

const contextInitialAccessManagementUserValues: trackedAccessManagementUser = {
    editing: false
};

type trackedInvitation = {
    editing?: boolean;
    invitation: Invitation | null;
    userGroups: UserGroup[];
};

const contextInitialInvitationValues: trackedInvitation = {
    invitation: null,
    userGroups: []
};

const formReducer = (state: any, updates: any) => {
    return {
        ...state,
        ...updates
    };
};

interface IInvitationFormContext {
    invitationState: trackedInvitation;
    updateInvitationState: Function;
}

interface IAccessManagementUserFormContext {
    accessManagementUserState: trackedAccessManagementUser;
    updateAccessManagementUserState: Function;
}

interface IInvitationFormContext {
    invitationState: trackedInvitation;
    updateInvitationState: Function;
}

interface IFormContext extends IInvitationFormContext, IAccessManagementUserFormContext, IInvitationFormContext {}

export const FormContext = createContext<IFormContext>({
    accessManagementUserState: contextInitialAccessManagementUserValues,
    invitationState: contextInitialInvitationValues,
    updateAccessManagementUserState: () => {
        return null;
    },
    updateInvitationState: () => {
        return null;
    }
});

export const FormProvider = ({ children }: { children: any }) => {
    const [accessManagementUserState, updateAccessManagementUserState] = useReducer(
        formReducer,
        contextInitialAccessManagementUserValues
    );
    const [invitationState, updateInvitationState] = useReducer(formReducer, contextInitialInvitationValues);

    const value = {
        accessManagementUserState,
        invitationState,
        updateAccessManagementUserState,
        updateInvitationState
    };

    return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
};

export const useFormContext = () => {
    return useContext(FormContext);
};
