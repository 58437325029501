import { IIdentityRepository } from "../repositories/IdentityRepository";
import { Collection } from "../domain/Collection";
import { Invitation } from "../domain/Invitation";
import { InvitationAdapter } from "./InvitationAdapter";

export interface IInvitationService {
    getInvitations({
        createdBy,
        inviteeEmail,
        organizationId,
        size,
        page
    }: {
        createdBy?: string;
        inviteeEmail?: string;
        organizationId?: string;
        size?: number;
        page?: number;
    }): Promise<Collection<Invitation>>;

    createInvitation(invitation: Partial<Invitation>): Promise<Invitation>;

    resendInvitation({ invitationId }: { invitationId: string }): Promise<void>;

    revokeInvitation({
        invitationId,
        requireAcceptance = true
    }: {
        invitationId: string;
        requireAcceptance?: boolean;
    }): Promise<void>;
}

export class InvitationService implements IInvitationService {
    identityRepository: IIdentityRepository;
    invitationAdapter: InvitationAdapter;

    constructor(identityRepository: IIdentityRepository) {
        this.identityRepository = identityRepository;
        this.invitationAdapter = new InvitationAdapter();
    }

    public async getInvitations({
        createdBy,
        inviteeEmail,
        organizationId,
        size,
        page
    }: {
        createdBy?: string;
        inviteeEmail?: string;
        organizationId?: string;
        size?: number;
        page?: number;
    }): Promise<Collection<Invitation>> {
        const { items, totalCount } = await this.identityRepository.getInvitations({
            createdBy,
            inviteeEmail,
            organizationId,
            size,
            page
        });

        return new Collection<Invitation>({
            items: items.map(this.invitationAdapter.adaptToDomain),
            totalCount
        });
    }

    public async createInvitation(invitation: Invitation): Promise<Invitation> {
        const { firstName, lastName, email, userGroups, requireAcceptance, organizationId, reasonForAccess } =
            invitation;
        const invitationDetails: Partial<Invitation> = {
            id: "0",
            createdBy: "",
            sentDates: [],
            created: new Date(),
            modified: new Date(),
            firstName,
            lastName,
            email: email.toLowerCase(),
            organizationId,
            requireAcceptance,
            userGroups
        };
        if (reasonForAccess) {
            invitationDetails["reasonForAccess"] = reasonForAccess;
        }
        const newInvitation = new Invitation(invitationDetails as Invitation);

        const restInvitation = this.invitationAdapter.adaptToRest(newInvitation);
        const response = await this.identityRepository.createInvitation(restInvitation);
        return this.invitationAdapter.adaptToDomain(response);
    }

    public async revokeInvitation({
        invitationId,
        requireAcceptance = true
    }: {
        invitationId: string;
        requireAcceptance?: boolean;
    }): Promise<void> {
        return await this.identityRepository.revokeInvitation(invitationId, requireAcceptance);
    }

    public async resendInvitation({ invitationId }: { invitationId: string }): Promise<void> {
        return await this.identityRepository.resendInvitation(invitationId);
    }
}
