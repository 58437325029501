import React from "react";
import { useUser } from "../../../hooks/useUser/useUser";
import { UserTableRow } from "../UserTableRow/UserTableRow";
import { Skeleton } from "../Skeleton/Skeleton";

type UserTableRowProps = {
    onRemove?: () => void;
    removeTitle?: string;
    email: string;
};
export const NotificationUserTableRow = ({ onRemove, removeTitle = "Remove user", email }: UserTableRowProps) => {
    const { user, isLoading } = useUser({ email, includeExternalStatus: true });

    if (isLoading || !user) return <Skeleton width="100%" height="32px" className="display--block push--bottom" />;

    return <UserTableRow user={user!} onRemove={onRemove} removeTitle={removeTitle} />;
};

NotificationUserTableRow.displayName = "NotificationUserTableRow";
