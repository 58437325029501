export enum Flags {
    BILLING_NOTIFICATION_ENABLED_PRODUCTS = "billing_notification_enabled_products",
    DISABLE_CUSTOM_ROLE_CREATION = "disable_custom_role_creation",
    ENABLE_ACTIVITY_LOG = "enable_activity_log",
    ENABLE_ACCESS_MANAGEMENT = "enable_access_management",
    ENABLE_BILLING_NOTIFICATION_SETTINGS = "enable_billing_notifications",
    ENABLE_BREADCRUMBS = "enable_breadcrumbs",
    ENABLE_CUSTOM_CMS_NAVIGATIN_URL = "enable_custom_cms_navigation_url",
    ENABLE_EXPLICIT_ROLE_TYPE_SELECTION = "enable_explicit_role_selection",
    ENABLE_GEN_AI = "enable_genai_settings",
    ENABLE_GROUP_ACCESS_FLOW = "enable_group_access_flow",
    ENABLE_INVITATION_FLOW = "enable_invitation_flow",
    ENABLE_MASTER_DASHBOARD = "enable_master_dashboard",
    ENABLE_SUPPORT_FEATURES = "enable_support_features",
    FILTER_GROUPS_AND_ROLES_PER_ENTITLEMENTS = "filter_groups_and_roles_per_entitlements",
    HIDE_PRODUCT_GROUPS = "hide_product_groups",
    HIDE_SUPPORT_ORGANIZATIONS = "hide_support_organizations",
    SHOW_USER_GROUP_OWNER_COLUMN_FLAG = "show_user_group_owner_column",
    SHOW_USER_GROUP_USER_COUNT_COLUMN_FLAG = "show_user_group_user_count_column",
    SHOW_ROLES = "show_roles",
    SHOW_ROLES_SIDEBAR_NAVIGATION = "enable_roles_sidebar_navigation",
    SHOW_INVITATIONS = "show_invitations",
    SHOW_LOGIN_STATUS = "show_login_status",
    SHOW_SUPPORT_USAGE_TAB = "support_page_usage_and_billing_tab",
    SHOW_USAGE_AND_BILLING = "show_usage_and_billing",
    SHOW_USER_ACTIVATION_STATUS = "show_user_activation_status",
    USE_ATTRIBUTE_PERMISSIONS = "use_attribute_based_permissions",
    SUPPORT_PAGE_UPDATES = "support_page_updates",
    INSTANCE_USER_MANAGER_UPDATES = "instance_user_manager_updates",
    ENABLE_COOKIELESS_EMBEDDING = "enable_cookieless_embedding",
    SSO_SUPPORTED_LOCAL_LOGIN = "sso_supported_local_login",
    ENABLE_CMP_ROLE_ENHANCEMENT = "enable_cmp_role_enhancement",
    RESTRICTED_SUPPORT_ORGS = "restrict_to_support_only"
}
