import React from "react";
import { ManagerSideNav } from "@optimizely/axiom";
import { NavLink, useLocation } from "react-router-dom";
import classNames from "classnames";
import { isChildPath } from "../../../lib/navigation-helpers";
import { useFeatureFlag } from "../../../hooks/useFeatureFlag/useFeatureFlag";
import { Flags } from "../../../feature-flags/flags";

export const NavigationBarSettingsSubMenu = () => {
    const location = useLocation();
    const pathname = location.pathname;
    const { enabled: genAIEnabled } = useFeatureFlag(Flags.ENABLE_GEN_AI);

    if (!isChildPath({ pathname: location.pathname, path: "/settings/" })) {
        return null;
    }
    const menuItemClasses = (route: string) => {
        return classNames({
            "oui-manager-side-nav__custom-link": true
        });
    };

    return (
        <ManagerSideNav>
            <ManagerSideNav.Header hasBackLink={false} primaryTitle="Settings" projectName="" isFullHeight />
            <ManagerSideNav.NavList>
                <ManagerSideNav.NavItem
                    isActive={isChildPath({ pathname, path: "/settings/authentication" })}
                    leftContent={
                        <NavLink
                            className={menuItemClasses("/settings/authentication")}
                            to="/settings/authentication/sso"
                        >
                            Authentication
                        </NavLink>
                    }
                />
                <ManagerSideNav.NavItem
                    isActive={isChildPath({ pathname, path: "/settings/notifications" })}
                    leftContent={
                        <NavLink className={menuItemClasses("/settings/notifications")} to="/settings/notifications">
                            Notifications
                        </NavLink>
                    }
                />
                {genAIEnabled && (
                    <ManagerSideNav.NavItem
                        isActive={isChildPath({ pathname, path: "/settings/genai" })}
                        leftContent={
                            <NavLink className={menuItemClasses("/settings/genai")} to="/settings/genai">
                                Generative AI
                            </NavLink>
                        }
                    />
                )}
            </ManagerSideNav.NavList>
        </ManagerSideNav>
    );
};

NavigationBarSettingsSubMenu.displayName = "NavigationBarSettingsSubMenu";
