import { Button } from "@optimizely/axiom";
import {
    ADMINCENTER_CUSTOM_GROUP_ACCESS_REVIEW_EVENT_NAME,
    ALL_PROJECTS_ID,
    ALL_PROJECTS_NAME,
    ATTRIBUTE_ROLES,
    GROUP_TYPES
} from "../../../../constants/constants";
import LimitByRole from "../../LimitByRole/LimitByRole";
import { AccessDetailsTable } from "../AccessDetailsTable/AccessDetailsTable";
import { AccessManagementUserFormAccessHeader } from "../AccessManagementUserForm/AccessManagementUserFormAccessHeader";
import { AddProductAccessPermission, AddProductAccessForm } from "../AddProductAccess/AddProductAccessForm";
import { useEffect, useState } from "react";
import { useAccessFlowUserFormContext } from "../AccessManagementUserForm/AccessManagementUserFormProvider";
import { AddGroupAccessForm } from "../AddGroupAccess/AddGroupAccessForm";
import { CustomGroupAccessConfirmationModal } from "../CustomGroupAccessConfirmation/CustomGroupAccessConfirmationModal";
import { UserGroup } from "../../../../domain/UserGroup";
import { getExperimentationProjectNamesAndIds } from "../../../../lib/access-helpers";
import { AccessDetailsByProductId, AccessDetailsRow } from "../../../../domain/AccessDetails";

export type UserGroupWithProjectDetails = Partial<UserGroup> & {
    // TODO: This should only need to support the array but with our current implementations
    // support for both is the easiest to accomodate all use cases with least refactoring.
    // NOTE: This is strictly for analytics at the time of writing :)
    projectId?: string;
    projectName?: string;
    projectIds?: string[];
    projectNames?: string[];
};
export const InvitationAccessForm = ({
    className,
    email,
    organizationId,
    setSelectedRoleGroups
}: {
    className?: string;
    email: string | null;
    organizationId: string | undefined;
    setSelectedRoleGroups: React.Dispatch<React.SetStateAction<UserGroupWithProjectDetails[]>>;
}) => {
    const [accessFormType, setAccessFormType] = useState<"Product" | "Custom Group" | undefined>(undefined);
    const [accessDetailsByProductId, setAccessDetailsByProductId] = useState<AccessDetailsByProductId>({});
    const { updateCustomGroupAccess, setUpdateCustomGroupAccess } = useAccessFlowUserFormContext();

    useEffect(() => {
        const handleEvent = (e: CustomEvent) => {
            const { action, userGroup } = e.detail;
            setUpdateCustomGroupAccess({ action, id: userGroup.id, name: userGroup.name, userGroup });
        };

        window.addEventListener(
            ADMINCENTER_CUSTOM_GROUP_ACCESS_REVIEW_EVENT_NAME,
            handleEvent as EventListenerOrEventListenerObject
        );

        return () => {
            window.removeEventListener(
                ADMINCENTER_CUSTOM_GROUP_ACCESS_REVIEW_EVENT_NAME,
                handleEvent as EventListenerOrEventListenerObject
            );
        };
    }, [setUpdateCustomGroupAccess]);

    const handleSave = ({ permission }: { permission: AddProductAccessPermission }) => {
        if (!permission || !permission.product || !permission.instance) {
            return;
        }

        const { product, instance, group, role, project } = permission;

        // Update selectedRoleGroups with the new role group
        setSelectedRoleGroups((prevGroups) => {
            const roleGroupWithProjectDetails = project
                ? {
                      ...group,
                      projectId: project.id,
                      projectName: project.name
                  }
                : group;
            if (prevGroups) {
                return [...prevGroups, roleGroupWithProjectDetails];
            }
            return [roleGroupWithProjectDetails];
        });

        const productId = product.id;
        const instanceId = instance.id;

        // Get the current accessDetailsByProductId
        const updatedAccessDetailsByProductId = { ...accessDetailsByProductId };

        // Get the existing ProductAccess object or create a new one
        const productAccess = updatedAccessDetailsByProductId[productId] || {
            productName: product.name,
            instanceAccess: {}
        };

        // Get the existing AccessDetailsRow array or create a new one
        const accessDetailRows = productAccess.instanceAccess[instanceId] || [];

        // Always create a new AccessDetailsRow for each role
        const newInstanceRow: AccessDetailsRow = {
            instanceName: instance.name,
            roles: [role], // Single role per AccessDetailsRow
            nickname: instance.nickname,
            projectId: role.isInstanceRole ? ALL_PROJECTS_ID : project?.id,
            projectName: role.isInstanceRole ? ALL_PROJECTS_NAME : project?.name,
            groupId: group.id,
            groupName: group.name,
            isCustomGroup: group.groupType === GROUP_TYPES.CUSTOM
        };

        // Add the new AccessDetailsRow to the accessDetailsRows array
        accessDetailRows.push(newInstanceRow);

        // Assign the updated rows back to the product access
        productAccess.instanceAccess[instanceId] = accessDetailRows;

        // Set the updated ProductAccess in the accessDetailsByProductId object
        updatedAccessDetailsByProductId[productId] = productAccess;

        // Update the state with the modified accessDetailsByProductId
        setAccessDetailsByProductId(updatedAccessDetailsByProductId);
        closeAccessForm();
    };

    const handleRemove = ({ accessDetailsRow }: { accessDetailsRow: AccessDetailsRow }) => {
        setAccessDetailsByProductId((prevDetails) => {
            // Create a copy of the current accessDetails
            const updatedDetails = { ...prevDetails };

            // Iterate through all productAccess entries
            for (const productId in updatedDetails) {
                const productAccess = updatedDetails[productId];

                // Iterate through all instanceAccess entries
                for (const instanceId in productAccess.instanceAccess) {
                    const accessDetailsRows = productAccess.instanceAccess[instanceId];

                    // Find the index of the accessDetailsRow to remove by matching groupId
                    const rowIndex = accessDetailsRows.findIndex((row) => row.groupId === accessDetailsRow.groupId);

                    if (rowIndex >= 0) {
                        // Remove the specific AccessDetailsRow
                        accessDetailsRows.splice(rowIndex, 1);

                        // If no rows are left, remove the accessDetail entry
                        if (accessDetailsRows.length === 0) {
                            delete productAccess.instanceAccess[instanceId];
                        }
                    }
                }

                // If no instanceAccess entries are left, remove the productAccess entry
                if (Object.keys(productAccess.instanceAccess).length === 0) {
                    delete updatedDetails[productId];
                }
            }

            return updatedDetails;
        });

        // Remove the related group from selectedRoleGroups
        setSelectedRoleGroups((prevGroups) => {
            if (!prevGroups) return prevGroups;

            // Extract the group that needs to be removed
            const groupToRemove = accessDetailsRow.groupId;

            // Filter out the group from selectedRoleGroups
            return prevGroups.filter((group) => groupToRemove !== group?.id);
        });
    };

    const closeAccessForm = () => {
        setAccessFormType(undefined);
    };

    const submitAddCustomGroupAccess = async () => {
        // get the project names and ids associated with the group for analytics
        const [projectIds, projectNames] = getExperimentationProjectNamesAndIds({
            accessDetails: updateCustomGroupAccess?.accessDetails || {}
        });

        const newCustomGroupToAdd: UserGroupWithProjectDetails = {
            ...(updateCustomGroupAccess?.userGroup || {}),
            projectIds,
            projectNames
        };

        setSelectedRoleGroups((prev) => [...prev, newCustomGroupToAdd]);
        const customGroupAccessDetails = updateCustomGroupAccess?.accessDetails;

        if (!!customGroupAccessDetails) {
            setAccessDetailsByProductId((tableAccessDetailsByProductId) => {
                const tableAccess = { ...tableAccessDetailsByProductId };
                // iterate over each productId in the selected custom group
                Object.keys(customGroupAccessDetails).forEach((productId) => {
                    // merge custom group and previous table product access
                    if (!tableAccess[productId]) {
                        tableAccess[productId] = customGroupAccessDetails[productId];
                    } else {
                        const tableInstanceAccess = tableAccess[productId]?.instanceAccess;
                        // iterate over each instanceId of the already listed product in the selected custom group
                        Object.keys(customGroupAccessDetails[productId].instanceAccess).forEach((instanceId) => {
                            // merge custom group and previous table instance access
                            if (!tableInstanceAccess[instanceId]) {
                                tableInstanceAccess[instanceId] =
                                    customGroupAccessDetails[productId].instanceAccess[instanceId];
                            } else {
                                tableInstanceAccess[instanceId] = [
                                    ...tableInstanceAccess[instanceId],
                                    ...customGroupAccessDetails[productId].instanceAccess[instanceId]
                                ];
                            }
                        });
                    }
                });
                return tableAccess;
            });
        }

        setUpdateCustomGroupAccess(undefined);
        setAccessFormType(undefined);
    };

    const cancelAddCustomGroupAccess = () => {
        setUpdateCustomGroupAccess(undefined);
    };

    return (
        <div {...(className && { className })}>
            <AccessManagementUserFormAccessHeader>
                <LimitByRole action={[ATTRIBUTE_ROLES.GROUPS.UPDATE, ATTRIBUTE_ROLES.GROUPS.MANAGE]} mode="hide">
                    <div className="flex justify-content-between align-items-center">
                        <Button
                            className="push--right"
                            // eslint-disable-next-line react/style-prop-object
                            style="outline"
                            isDisabled={!!accessFormType || !email}
                            whiteBackground
                            onClick={() => setAccessFormType("Product")}
                        >
                            Add Product Access
                        </Button>
                        <LimitByRole action={[ATTRIBUTE_ROLES.GROUPS.UPDATE]} mode="hide">
                            <Button
                                className="push--right"
                                // eslint-disable-next-line react/style-prop-object
                                style="outline"
                                isDisabled={!!accessFormType || !email}
                                whiteBackground
                                onClick={() => setAccessFormType("Custom Group")}
                            >
                                Add Group Access
                            </Button>
                        </LimitByRole>
                    </div>
                </LimitByRole>
            </AccessManagementUserFormAccessHeader>
            {accessFormType === "Product" && (
                <AddProductAccessForm
                    className="push-double--top push-double--bottom"
                    onSubmit={handleSave}
                    onClose={closeAccessForm}
                    isSaving={false}
                />
            )}
            {accessFormType === "Custom Group" && (
                <AddGroupAccessForm
                    onClose={closeAccessForm}
                    organizationId={organizationId}
                    email={email || ""}
                    customGroups
                />
            )}
            {!!updateCustomGroupAccess && (
                <CustomGroupAccessConfirmationModal
                    action={updateCustomGroupAccess.action}
                    errorMessage=""
                    isSaving={false}
                    userGroup={updateCustomGroupAccess.userGroup!}
                    onClose={cancelAddCustomGroupAccess}
                    onSubmit={submitAddCustomGroupAccess}
                />
            )}
            <AccessDetailsTable accessDetails={accessDetailsByProductId} onRemove={handleRemove} />
        </div>
    );
};

InvitationAccessForm.displayName = "InvitationAccessForm";
